import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * StatusFilter Component
 * A single-select filter that uses the original markup and classnames.
 * It is controlled by the parent via the `value` prop.
 * Its selected value is also saved in sessionStorage using the provided storageKey.
 *
 * Props:
 * - name: Label for the filter (e.g. "Status")
 * - options: Array of option strings (e.g. ["TBC", "WIP*", "WIP", "DONE"])
 * - storageKey: The key to use when saving the value (e.g. "status_jobs")
 * - value: The current selected value (controlled from parent)
 * - onChange: Callback function(newValue) when a new option is selected.
 */
const StatusFilter = ({ name, options, storageKey, value, onChange }) => {
  // Local state for showing/hiding the dropdown
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  // Whenever the value changes, update sessionStorage.
  useEffect(() => {
    sessionStorage.setItem(storageKey, value);
  }, [value, storageKey]);

  // Close the dropdown when clicking outside.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="Filters" ref={popupRef}>
      <div className="Item" onClick={() => setIsOpen(!isOpen)}>
        <div className="items">
          <div className="Name">{name} :</div>
          <div className="FilterItems">
            {value && value.length > 10 ? value.slice(0, 10) + "..." : value || "All"}
          </div>
        </div>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.76117 0.5L5 3.51432L1.23883 0.5L0 1.49284L5 5.5L10 1.49284L8.76117 0.5Z"
            fill="#0035F0"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="FilterOptions">
          {options.length > 0 ? (
            options.map((option) => (
              <div
                className={`Options ${value === option ? "Active" : ""}`}
                key={option}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="Options">No options</div>
          )}
        </div>
      )}
    </div>
  );
};

StatusFilter.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  storageKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StatusFilter;
