import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InactivePopup } from "../../reducers/AllApis";
import { formatDateRange, formatMonth } from "../../utils/utils";

const DateRangeFilter = forwardRef(
  (
    {
      handleSelectData,
      handleSelectDataCustom,
      Data,
      customStartKey,
      customEndKey,
      customStartDate,
      customEndDate,
      handleClearFil,
    },
    ref
  ) => {
    

    
    const dispatch = useDispatch();
    const [StartDate, setStartDate] = useState(sessionStorage.getItem(customStartKey));
    const [EndDate, setEndDate] = useState(sessionStorage.getItem(customEndKey));
    const [isNotActive, setIsNotActive] = useState(false);

    const handleSelect = (data) => {
      handleSelectData(data);
      setTimeout(() => {
        dispatch(InactivePopup());
      }, 500);
    };

    const CustomDate = ({ date, setDate, name, sessionKey,Title }) => {
      const [ShowSelect, setShowSelect] = useState(false);

      const handleChange = (e) => {
        const newValue = e.target.value;
        setDate(newValue);
        handleSelectDataCustom(newValue, sessionKey,name );

        if (StartDate && EndDate) {
          setTimeout(() => {
            dispatch(InactivePopup());
          }, 500);
        }
      };

      useEffect(() => {
        if (StartDate || EndDate) {
          setIsNotActive(true);
        }
      }, []);

      return (
        <div
          className={`InputDiv CustomInput ${
            isNotActive && date ? "active" : isNotActive && !date ? "NotActive" : ""
          }`}
        >
          <div className="Head">{Title}</div>
          <div className="Head">
            {ShowSelect ? (
              <input type="date" onChange={handleChange} value={date || ""} />
            ) : (
              <span onClick={() => setShowSelect(true)}>
                {date ? formatMonth(date) : "Select or Type"}
              </span>
            )}
          </div>
        </div>
      );
    };

    const handleClear = () => {
      handleClearFil();
    };


    return (
      <div className="AddJobsComp DateRangeFilter" ref={ref}>
        <div className="InputDiv Close">
        <div className="Head">
           
          </div>
          {/* <div className="Head" onClick={handleClear}>
            Clear filters
          </div> */}
          <div className="Head">
            <svg
              onClick={() => dispatch(InactivePopup())}
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.9 14L0.5 12.6L6.1 7L0.5 1.4L1.9 0L7.5 5.6L13.1 0L14.5 1.4L8.9 7L14.5 12.6L13.1 14L7.5 8.4L1.9 14Z"
                fill="currentcolor"
              />
            </svg>
          </div>
        </div>
        <div
          className={`InputDiv ${Data === "this_week" ? "active" : ""}`}
          onClick={() => handleSelect("this_week")}
        >
          <div className="Head">This week</div>
          <div className="Head">{formatDateRange("this_week")}</div>
        </div>
        <div
          className={`InputDiv ${Data === "last_week" ? "active" : ""}`}
          onClick={() => handleSelect("last_week")}
        >
          <div className="Head">Last week</div>
          <div className="Head">{formatDateRange("last_week")}</div>
        </div>
        <div className="InputDivDivider"></div>
        <div
          className={`InputDiv ${Data === "this_month" ? "active" : ""}`}
          onClick={() => handleSelect("this_month")}
        >
          <div className="Head">This month</div>
          <div className="Head">{formatDateRange("this_month")}</div>
        </div>
        <div
          className={`InputDiv ${Data === "this_quarter" ? "active" : ""}`}
          onClick={() => handleSelect("this_quarter")}
        >
          <div className="Head">This quarter</div>
          <div className="Head">{formatDateRange("this_quarter")}</div>
        </div>
        <div
          className={`InputDiv ${
            Data === "this_financial_year" ? "active" : ""
          }`}
          onClick={() => handleSelect("this_financial_year")}
        >
          <div className="Head">This financial year</div>
          <div className="Head">{formatDateRange("this_financial_year")}</div>
        </div>

        <div className="InputDivDivider"></div>

        <div
          className={`InputDiv ${Data === "last_month" ? "active" : ""}`}
          onClick={() => handleSelect("last_month")}
        >
          <div className="Head">Last month</div>
          <div className="Head">{formatDateRange("last_month")}</div>
        </div>
        <div
          className={`InputDiv ${Data === "last_quarter" ? "active" : ""}`}
          onClick={() => handleSelect("last_quarter")}
        >
          <div className="Head">Last quarter</div>
          <div className="Head">{formatDateRange("last_quarter")}</div>
        </div>
        <div
          className={`InputDiv ${
            Data === "last_financial_year" ? "active" : ""
          }`}
          onClick={() => handleSelect("last_financial_year")}
        >
          <div className="Head">Last financial year</div>
          <div className="Head">{formatDateRange("last_financial_year")}</div>
        </div>

        <div className="InputDivDivider"></div>

        <div
          className={`InputDiv ${Data === "month_to_date" ? "active" : ""}`}
          onClick={() => handleSelect("month_to_date")}
        >
          <div className="Head">Month to date</div>
          <div className="Head">{formatDateRange("month_to_date")}</div>
        </div>
        <div
          className={`InputDiv ${Data === "quarter_to_date" ? "active" : ""}`}
          onClick={() => handleSelect("quarter_to_date")}
        >
          <div className="Head">Quarter to date</div>
          <div className="Head">{formatDateRange("quarter_to_date")}</div>
        </div>
        <div
          className={`InputDiv ${Data === "year_to_date" ? "active" : ""}`}
          onClick={() => handleSelect("year_to_date")}
        >
          <div className="Head">Year to date</div>
          <div className="Head">{formatDateRange("year_to_date")}</div>
        </div>
        <div className="InputDivDivider"></div>
        <CustomDate
          date={StartDate}
          setDate={setStartDate}
          name={customStartKey}
          sessionKey={customStartKey}
                  Title="Custom Start Date"
        />
        <CustomDate
          date={EndDate}
          setDate={setEndDate}
          name={customEndKey}
          sessionKey={customEndKey}
          Title="Custom End Date"
        />
         
      </div>
    );
  }
);

export default DateRangeFilter;
