import React, { useState } from "react";

const CustomerInformation = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Customer Name</div>
          <div className="Item subitem">Contact Information</div>
          <div className="Item subitem">Billing Address</div>
          <div className="Item subitem">Site Address</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">John Doe</div>
        </div>
        <div className="DataTable">
          <div className="Item">john@example.com | +1 234 567 890</div>
        </div>
        <div className="DataTable">
          <div className="Item">123 Main St, IL, USA</div>
        </div>
        <div className="DataTable">
          <div className="Item">123 Main St, IL, USA</div>
        </div>
      </div>
    </div>
  );
};

const ProjectDetails = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Project Name/Title</div>
          <div className="Item subitem">Project Description</div>
          <div className="Item subitem">Job Number</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">Example project name</div>
        </div>
        <div className="DataTable">
          <div className="Item">Example project description</div>
        </div>
        <div className="DataTable">
          <div className="Item">J12345</div>
        </div>
      </div>
    </div>
  );
};

const QuotationDetails = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Quotation Number</div>
          <div className="Item subitem">Date of Quotation</div>
          <div className="Item subitem">Validity Date</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">Q67890</div>
        </div>
        <div className="DataTable">
          <div className="Item">2023-10-15</div>
        </div>
        <div className="DataTable">
          <div className="Item">2023-11-15</div>
        </div>
      </div>
    </div>
  );
};

const ScopeofWork = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Work Breakdown</div>
          <div className="Item subitem">Waterproofing Areas</div>
          <div className="Item subitem">Materials and Techniques</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">Example one, Example two, Example three </div>
        </div>
        <div className="DataTable">
          <div className="Item">Example one, Example two</div>
        </div>
        <div className="DataTable">
          <div className="Item">Example one, Example two, Example three</div>
        </div>
      </div>
    </div>
  );
};

const CostingDetails = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Line Items</div>
          <div className="Item subitem">Labor Charges($)</div>
          <div className="Item subitem">Subtotals($)</div>
          <div className="Item subitem">Taxes($)</div>
          <div className="Item subitem">Total Cost($)</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">Materials, Labor</div>
        </div>
        <div className="DataTable">
          <div className="Item">2,500</div>
        </div>
        <div className="DataTable">
          <div className="Item">5,000</div>
        </div>
        <div className="DataTable">
          <div className="Item">500</div>
        </div>
        <div className="DataTable">
          <div className="Item">5,500</div>
        </div>
      </div>
    </div>
  );
};

const TermsConditions = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Payment Terms</div>
          <div className="Item subitem">Warranty/Guarantee Details</div>
          <div className="Item subitem">Cancellation and Refund Policy</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">50% advance, 50% on completion</div>
        </div>
        <div className="DataTable">
          <div className="Item">10-year warranty on materials and labor</div>
        </div>
        <div className="DataTable">
          <div className="Item">Full refund if canceled before work begins</div>
        </div>
      </div>
    </div>
  );
};

const Scheduling = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Start Date</div>
          <div className="Item subitem">Duration</div>
          <div className="Item subitem">Key Milestones</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">2023-11-01</div>
        </div>
        <div className="DataTable">
          <div className="Item">2 weeks</div>
        </div>
        <div className="DataTable">
          <div className="Item">Foundation work, Waterproofing, Inspection</div>
        </div>
      </div>
    </div>
  );
};

const ApprovalSection = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Client’s Digital Signature</div>
          <div className="Item subitem">Company Representative’s Signature</div>
          <div className="Item subitem">Date of Client Approval</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">[Digital Signature]</div>
        </div>
        <div className="DataTable">
          <div className="Item">[Representative Signature]</div>
        </div>
        <div className="DataTable">
          <div className="Item">2023-10-20</div>
        </div>
      </div>
    </div>
  );
};

const NotesAttachmentsStatus = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Additional Notes</div>
          <div className="Item subitem">Site Photos, Plans</div>
          <div className="Item subitem">Quotation Status</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">Example notes</div>
        </div>
        <div className="DataTable">
          <div className="Item">[Attached Photos and Plans]</div>
        </div>
        <div className="DataTable">
          <div className="Item">Approved</div>
        </div>
      </div>
    </div>
  );
};

const Integration = () => {
  return (
    <div className="TableNew">
      <div className="TableFieldNames">
        <div className="TableStaticHeading">
          <div className="Item subitem">Integrate with Xero</div>
          <div className="Item subitem">Integrate with SimPRO</div>
        </div>
      </div>
      <div className="TableFieldData">
        <div className="DataTable">
          <div className="Item">Enabled</div>
        </div>
        <div className="DataTable">
          <div className="Item">Enabled</div>
        </div>
      </div>
    </div>
  );
};

const Quotation = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { label: "Customer Information", component: <CustomerInformation /> },
    { label: "Project Details", component: <ProjectDetails /> },
    { label: "Quotation Details", component: <QuotationDetails /> },
    { label: "Scope of Work", component: <ScopeofWork /> },
    { label: "Costing Details", component: <CostingDetails /> },
    { label: "Terms and Conditions", component: <TermsConditions /> },
    { label: "Scheduling", component: <Scheduling /> },
    { label: "Notes, Attachments and Status", component: <NotesAttachmentsStatus /> },
    { label: "Approval Section", component: <ApprovalSection /> },
    { label: "Integration Options", component: <Integration /> },
  ];

  return (
    <div className="Quotation">
      <div style={{ overflow: "hidden" }}>
        <div className="vertical-tabs-container">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`tab-button ${activeTab === index ? "active" : ""}`}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="tab-content">{tabs[activeTab].component}</div>
        </div>
      </div>
    </div>
  );
};

export default Quotation;