import numeral from "numeral";
import { formatMonth, formatMonth1, formatYear, NegativeData } from "../../../utils/utils";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectTimeFilter } from "../../../reducers/AllApis";
import { InfoComp } from "./../../../utils/utils";

const LabourAnalysis = ({
  LabourInsightsHeader = [],
  TotalActualLabourRev = 0,
  ActualLabourRevArr = [],
  TotalActualLabourCost = 0,
  ActualLabourCostArr = [],
  TotalGrossPAL = 0,
  GrossPAL = [],
  TotalPerMargin = 0,
  PercentMargin = [],
  Time_Sheet = [],
  TotalForecastedLabourRevenue = 0,
  ForecastedLabourRevenue = [],
  TotalLabourRevenueVariance = 0,
  LabourRevenueVariance = [],
  TotalLabourRevenueVariancePer = 0,
  LabourRevenueVariancePer = [],
  TotalActualLabourHours = 0,
  ActualLabourHours = [],
  TotalLabourHoursTarget = 0,
  LabourHoursTarget = [],
  TotalLabourHourLG = 0,
  LabourHourLG = [],
  BudgetedLabourCostArr = [],
  TotalBudgetedLabourCost = 0,
  TotalEarnedValueArr = 0,
  EarnedValueArr = [],
  InvoicingTotalArr = [],
  TotalInvoicingTotalArr = 0,
}) => {
  const maxColumns = Math.max(
    LabourInsightsHeader.length,
    ActualLabourRevArr.length,
    ActualLabourCostArr.length,
    GrossPAL.length,
    PercentMargin.length,
    ForecastedLabourRevenue.length,
    LabourRevenueVariance.length,
    LabourRevenueVariancePer.length,
    ActualLabourHours.length,
    LabourHoursTarget.length,
    LabourHourLG.length,
    BudgetedLabourCostArr.length,
    EarnedValueArr.length,
    InvoicingTotalArr.length
  );

  // Helper to decide CSS class for negative or low percentages.
  const getClassName = (value, type) => {
    if (type === "percentage") {
      // For % margin, show red if < 40
      return Number(value) < 40 ? "reddata" : "green";
    } else {
      // For non-percentage, show red if negative
      return Number(value) < 0 ? "reddata" : "green";
    }
  };

  // Helper for cell content (for each column cell).
  const cellContent = (num, isPercentage, isCurrency) => {
    if (num < 0) {
      if (isPercentage) {
        // Negative with parentheses, 1 decimal place
        return <span style={{ color: "rgba(255, 67, 89, 1)" }}>-({Math.abs(num).toFixed(1)})</span>;
      } else if (isCurrency) {
        // Negative currency with commas
        return <span style={{ color: "rgba(255, 67, 89, 1)" }}>-({numeral(Math.abs(num)).format("0,0")})</span>;
      } else {
        // Negative integer
        return <span style={{ color: "rgba(255, 67, 89, 1)" }}>-({Math.abs(num).toFixed(0)})</span>;
      }
    } else {
      if (isPercentage) {
        return num.toFixed(1);
      } else if (isCurrency) {
        return numeral(num).format("0,0");
      } else {
        return num.toFixed(0);
      }
    }
  };

  // Helper to handle a row's data cells.
  const renderRow = (
    dataArr = [],
    totalValue = "0",
    formatFn = null,
    isPercentage = false,
    isCurrency = true,
    type = null
  ) => {
    return (
      <div className="DataTable">
        {Array.from({ length: maxColumns }).map((_, i) => {
          // Check numeric
          const cellValue = Number(dataArr[i]);
          const isNumeric = !isNaN(cellValue);

          return (
            <div
              key={i}
              className={`Item ${isNumeric ? getClassName(cellValue, type) : ""}`}
            >
              {isNumeric
                ? formatFn
                  ? formatFn(cellValue)
                  : cellContent(cellValue, isPercentage, isCurrency)
                : "0"}
            </div>
          );
        })}
      </div>
    );
  };

  // Helper for totals so negative totals also show parentheses in red
  const formatTotalValue = (value, isPercentage = false, decimals = 0) => {
    const num = Number(value) || 0;
    if (num < 0) {
      // Negative
      return isPercentage
        ? `-(${Math.abs(num).toFixed(decimals)})`
        : `-(${numeral(Math.abs(num)).format("0,0")})`;
    } else {
      // Positive or zero
      return isPercentage
        ? num.toFixed(decimals)
        : numeral(num).format("0,0");
    }
  };

  const dispatch = useDispatch();

  const detailspopupRef = useRef(null);
  const inputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const detailstogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      detailspopupRef.current &&
      !detailspopupRef.current.contains(event.target)
    ) {
      setIsPopupOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { SelectedTime } = useSelector((state) => state.allapi);
  const handleSelectTime = (data) => {
    dispatch(SelectTimeFilter(data));
  };

  return (
    <div className="LabourAnalysis labourcard ">
      {Time_Sheet.length > 0 ? (
        <div className="TableNew">
          <div className="TableFieldNames">
            <div className="TableStaticHeading">
              <div className="Item fixedheightheading">
                <div
                  className="detailsfilter"
                  onClick={detailstogglePopup}
                  ref={inputRef}
                  style={{ marginBottom: "-40px" }}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2212 18V16H14.2212V18H10.2212ZM6.22119 13V11H18.2212V13H6.22119ZM3.22119 8V6H21.2212V8H3.22119Z"
                      fill="currentColor"
                    />
                  </svg>
                  <p>
                    {SelectedTime === "monthly"
                      ? "Monthly"
                      : SelectedTime === "yearly"
                      ? "Yearly"
                      : SelectedTime === "quarterly"
                      ? "Quarterly"
                      : "View by"}
                  </p>

                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5427 8.95001L14.0227 15.47C13.2527 16.24 11.9927 16.24 11.2227 15.47L4.70267 8.95001"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {isPopupOpen && (
                    <div
                      className="graphpopup"
                      ref={detailspopupRef}
                      style={{
                        marginTop: "200px",
                        marginRight: "10px",
                      }}
                    >
                      <div className="popup-content">
                        <p onClick={() => handleSelectTime("monthly")}>Monthly</p>
                        <p onClick={() => handleSelectTime("yearly")}>Yearly</p>
                        <p onClick={() => handleSelectTime("quarterly")}>Quarterly</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="Item">
                Invoicing Total ($) <InfoComp text="Total Revenue of Invoice" />
              </div>
              <div className="Item subitem">
                Actual Labour Revenue ($){" "}
                <InfoComp text="(Total Revenue of Invoice * ((Total Labour Chargeout / Total Project) * 100)) / 100" />
              </div>
              <div className="Item subitem">
                Actual Labour Cost ($){" "}
                <InfoComp text="-(Actual Labour Hours * Labour CTC/H)" />
              </div>
              <div className="Item subitem">
                Budgeted Labour Cost ($){" "}
                <InfoComp text="-(Labour Hours Target * Labour CTC/H)" />
              </div>
              <div className="Item subitem">
                Earned Value ($){" "}
                <InfoComp text="(Actual Labour Cost - Budgeted Labour Cost)" />
              </div>
              <div className="Item subitem">
                Gross Profit Attributable to Labour ($){" "}
                <InfoComp text="(Actual Labour Revenue + Actual Labour Cost)" />
              </div>
              <div className="Item subitem">
                % Margin{" "}
                <InfoComp text="(Gross Profit Attributable to Labour / Actual Labour Revenue) * 100" />
              </div>
              <div className="Item divider"></div>
              <div className="Item">
                Forecasted Labour Revenue ($){" "}
                <InfoComp text="(Total Hours * Labour Chargeout/H)" />
              </div>
              <div className="Item subitem">
                Labour Revenue Variance ($){" "}
                <InfoComp text="(Actual Labour Revenue - Forecasted Labour Revenue)" />
              </div>
              <div className="Item subitem">
                Labour Revenue Variance (%){" "}
                <InfoComp text="(Labour Revenue Variance/Forecasted Labour Revenue) * 100" />
              </div>
              <div className="Item divider"></div>
              <div className="Item">
                Actual Labour Hours (H) <InfoComp text="Total Hours" />
              </div>
              <div className="Item subitem">
                Labour Hours Target (H){" "}
                <InfoComp text="(Actual Labour Revenue/Labour Chargeout/H)" />
              </div>
              <div className="Item subitem">
                Labour Hour Loss / Gain (H){" "}
                <InfoComp text="Labour Hours Target - Actual Labour Hours" />
              </div>
              <div className="Item divider"></div>
            </div>
          </div>

          {/* Totals row */}
          <div className="TableFieldData TableStaticField">
            <div className="DataTable Heading fixedheightheading">
              <div className="Item Total" style={{ borderRight: "0" }}>
                <div className="First" style={{ color: "#00000000" }}>
                  hi
                </div>
                <div
                  className="Second"
                  style={{ borderRight: "1px solid var(--item-total)" }}
                >
                  Total
                </div>
              </div>
            </div>

            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalInvoicingTotalArr) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalInvoicingTotalArr, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalActualLabourRev) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalActualLabourRev, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalActualLabourCost) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalActualLabourCost, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalBudgetedLabourCost) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalBudgetedLabourCost, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalEarnedValueArr) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalEarnedValueArr, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalGrossPAL) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalGrossPAL, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${
                  Number(TotalPerMargin) < 40 ? "reddata" : "green"
                }`}
              >
                {formatTotalValue(TotalPerMargin, true, 1)}
              </div>
            </div>
            <div className="dividertable"></div>

            <div className="DataTable">
              <div
                className={`Item Total ${
                  Number(TotalForecastedLabourRevenue) < 0 ? "reddata" : "green"
                }`}
              >
                {formatTotalValue(TotalForecastedLabourRevenue, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalLabourRevenueVariance) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalLabourRevenueVariance, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${
                  Number(TotalLabourRevenueVariancePer) < 0 ? "reddata" : "green"
                }`}
              >
                {formatTotalValue(TotalLabourRevenueVariancePer, true, 1)}
              </div>
            </div>
            <div className="dividertable"></div>

            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalActualLabourHours) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalActualLabourHours, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalLabourHoursTarget) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalLabourHoursTarget, false, 0)}
              </div>
            </div>
            <div className="DataTable">
              <div
                className={`Item Total ${Number(TotalLabourHourLG) < 0 ? "reddata" : "green"}`}
              >
                {formatTotalValue(TotalLabourHourLG, false, 0)}
              </div>
            </div>
          </div>

          {/* Column data rows */}
          <div className="TableFieldData TableFieldValues">
            <div className="DataTable Heading">
              {Array.from({ length: maxColumns }).map((_, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="Item">
                      {LabourInsightsHeader[i] ? (
                        <>
                          <div className="First">
                            {SelectedTime === "quarterly"
                              ? LabourInsightsHeader[i]
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")
                              : formatMonth1(LabourInsightsHeader[i])}
                          </div>
                          <div className="Second">
                            {SelectedTime === "monthly"
                              ? "Month"
                              : SelectedTime === "quarterly"
                              ? LabourInsightsHeader[i]
                              : ""}
                            {SelectedTime === "monthly" && i + 1}
                            {SelectedTime === "yearly" &&
                              `FY${formatYear(LabourInsightsHeader[i])}`}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="First" style={{ color: "#00000000" }}>
                            test
                          </div>
                          <div className="Second" style={{ color: "#00000000" }}>
                            test
                          </div>
                        </>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

            {renderRow(InvoicingTotalArr, numeral(TotalInvoicingTotalArr).format("0,0"))}
            {renderRow(ActualLabourRevArr, numeral(TotalActualLabourRev).format("0,0"))}
            {renderRow(ActualLabourCostArr, numeral(TotalActualLabourCost).format("0,0"))}
            {renderRow(BudgetedLabourCostArr, numeral(TotalBudgetedLabourCost).format("0,0"))}
            {renderRow(EarnedValueArr, numeral(TotalEarnedValueArr).format("0,0"))}
            {renderRow(GrossPAL, numeral(TotalGrossPAL).format("0,0"))}
            {renderRow(
              PercentMargin,
              `${TotalPerMargin ? TotalPerMargin.toFixed(1) : "0"}`,
              null,
              true,
              true,
              "percentage"
            )}

            <div className="dividertable"></div>

            {renderRow(ForecastedLabourRevenue, numeral(TotalForecastedLabourRevenue).format("0,0"))}
            {renderRow(
              LabourRevenueVariance,
              numeral(TotalLabourRevenueVariance).format("0,0"),
              null,
              false,
              true,
              "variance"
            )}
            {renderRow(
              LabourRevenueVariancePer,
              `${
                TotalLabourRevenueVariancePer
                  ? TotalLabourRevenueVariancePer.toFixed(1)
                  : "0"
              }`,
              null,
              true
            )}

            <div className="dividertable"></div>

            {renderRow(
              ActualLabourHours,
              `${TotalActualLabourHours ? TotalActualLabourHours.toFixed(0) : "0"}`,
              null,
              false,
              "H",
              false
            )}
            {renderRow(
              LabourHoursTarget,
              `${TotalLabourHoursTarget ? TotalLabourHoursTarget.toFixed(0) : "0"}`,
              null,
              false,
              "H",
              false
            )}
            {renderRow(
              LabourHourLG,
              TotalLabourHourLG ? numeral(TotalLabourHourLG).format("0,0") : "0",
              null,
              false,
              true,
              "variance"
            )}
          </div>
        </div>
      ) : (
        <div className="ItemHead">No Data</div>
      )}
    </div>
  );
};

export default LabourAnalysis;
