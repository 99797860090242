import React from 'react'
import SignIn from '../pages/Authentication/SignIn';
import JobDetails from '../pages/Details1';
import Settings from '../pages/Settings';
import Users from '../pages/Users';
import JobNew from '../pages/JobNew';
import ReportsNew from '../pages/ReportsNew';
import Supplier from '../pages/Supplier';
import Inventory from '../pages/Inventory';
import Asset from '../pages/Asset';
import Quotation from '../pages/Quotation';
import JobNew1 from './../pages/Test';

const coreRoutes = [
  {
    path: '/quotation',
    title: 'All Quotations',
    component:Quotation ,
  },
  {
    path: '/inventory',
    title: 'All Inventories',
    component:Inventory ,
  },
  {
    path: '/assets',
    title: 'All Assets',
    component:Asset ,
  },
  {
    path: '/supplier',
    title: 'All Suppliers',
    component:Supplier ,
  },
    {
      path: '/jobs',
      title: 'All Jobs',
      component:JobNew ,
    },
    {
      path: '/users',
      title: 'Users',
      component: Users,
    },
    {
        path: '/reports',
        title: 'Reports',
        component: ReportsNew,
      },
      // {
      //   path: '/',
      //   title: 'SignIn',
      //   component: SignIn,
      // },
      {
        path: '/jobs/:value',
        title: 'Details',
        component: JobDetails,
      },
      {
        path: '/settings',
        title: 'Settings',
        component: Settings,
      },
    
      {
        path: '/jobs1',
        title: 'Details',
        component: JobNew1,
      },
    
  ];

  
  const routes = [...coreRoutes];
  export default routes;