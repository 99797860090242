import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActivePopup,
  AddTags,
  InactiveJobPopup,
  InactivePopup,
  ReportCSV,
  ReportCSVSearch,
  ResetSuccessUpdate,
  Tags,
} from "../reducers/AllApis";
import {
  CompBar,
  formatMonth,
  PercentColor,
  ResizableColumn,
  StatFormat,
} from "../utils/utils";
import numeral from "numeral";
import Filter from "./../components/Jobs/Filters";
import DateRangeFilter from "../components/Jobs/DateRangeFilter";
import * as XLSX from "xlsx";
import { useReactToPrint } from "react-to-print";

import { Toaster } from "react-hot-toast";
import JobsFilter from "../components/JobsFilter";

const ReportsNew = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(
    Number(sessionStorage.getItem("currentPage-report")) || 1
  );
  const [searchTerm, setSearchTerm] = useState(
    sessionStorage.getItem("searchTerm-report") || ""
  );
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    status: sessionStorage.getItem("status-report") || "",
    dateRange: sessionStorage.getItem("dateRange-report") || "",
    tags: JSON.parse(sessionStorage.getItem("tags-report")) || "",
    projectManager: sessionStorage.getItem("projectManager-report") || "",
    start_date: sessionStorage.getItem("Custom_start_date_report") || "",
    end_date: sessionStorage.getItem("Custom_end_date_report") || "",
  });
  const storageKeys = {
    status: "status-report",
    dateRange: "dateRange-report",
    tags: "tags-report",
    projectManager: "projectManager-report",
    start_date: "start_date-report",
    end_date: "end_date-report",
  };
  // const SelectedReportRemove = JSON.parse(sessionStorage.getItem("SelectedReportRemove"))
  const [SelectedReportRemove, setSelectedReportRemove] = useState(() => {
    return JSON.parse(sessionStorage.getItem("SelectedReportRemove")) || null;
  });

  const hasEmptyValue = Object.values(filters).some((value) => value !== "");

  const {
    Listdata,
    ReportCount,
    ReportSearchCount,
    ListSearchdata,
    ReportSearchData,
    ReportData,
    TagsData,
    ProjectManagersData,
    OpenPopup,
    AddTagsSuccess,
  } = useSelector((state) => state.allapi);
  const JobPopupRef = useRef();

  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          JobPopupRef.current &&
          !JobPopupRef.current.contains(event.target)
        ) {
          dispatch(InactivePopup());
  
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(ReportCSVSearch({ search: debouncedSearchTerm }));
      sessionStorage.setItem("searchTerm-report", debouncedSearchTerm);
      sessionStorage.removeItem("currentPage-report");
      setCurrentPage(1);
    } else if (
      filters.status === "" &&
      filters.projectManager === "" &&
      filters.tags === "" &&
      filters.dateRange === "" &&
      filters.start_date === "" &&
      filters.end_date === ""
    ) {
      sessionStorage.removeItem("searchTerm-report");
      dispatch(ReportCSV({ page: currentPage }));
    }
  }, [
    debouncedSearchTerm,
    currentPage,
    dispatch,
    filters,
    SelectedReportRemove,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    sessionStorage.setItem("currentPage-report", page);
    if (!debouncedSearchTerm) {
      dispatch(ReportCSV({ page }));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const ListDataMain = debouncedSearchTerm
    ? ReportSearchData
    : SelectedReportRemove !== null && SelectedReportRemove.length > 0
    ? SelectedReportRemove
    : ReportData;

  const ListCountMain = debouncedSearchTerm
    ? ReportSearchCount
    : ReportCount || 0;

  const totalPages = Math.ceil(ListCountMain / 50);
  const handleFilterChange = (name, field, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [field]: value };

      if (name === "tags") {
        sessionStorage.setItem(name, JSON.stringify(value));
      } else {
        sessionStorage.setItem(name, value);
      }

      return newFilters;
    });
  };

  useEffect(() => {
    const params = new URLSearchParams();
    const cleanedObj = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== "")
    );

    filters &&
      filters.tags &&
      filters.tags.forEach((tag) => {
        params.append("tags", tag);
      });

    const data = {
      project_manager_name: filters.projectManager,
      status: filters.status,
      filter_type: filters.dateRange,
      custom_start_date: filters.start_date,
      custom_end_date: filters.end_date,
    };

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        params.append(key, value);
      }
    });

    if (
      params.toString() &&
      ((!filters.start_date && !filters.end_date) ||
        (filters.start_date && filters.end_date))
    ) {
      sessionStorage.setItem("FilterItems-report", JSON.stringify(cleanedObj));

      sessionStorage.removeItem("currentPage-report");
      dispatch(ReportCSV(params));
    }
  }, [
    filters.status,
    filters.dateRange,
    filters.tags,
    filters.projectManager,
    filters.start_date,
    filters.end_date,
    SelectedReportRemove,
  ]);

  const handleDatePopup = () => {
    dispatch(ActivePopup());
  };

  const handleSelectData = (data) => {
    sessionStorage.setItem("dateRange-report", data);

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, dateRange: data };
      return newFilters;
    });
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem("status-report");
    sessionStorage.removeItem("dateRange-report");
    sessionStorage.removeItem("tags-report");
    sessionStorage.removeItem("projectManager-report");
    sessionStorage.removeItem("Custom_end_date_report");
    sessionStorage.removeItem("Custom_start_date_report");
    sessionStorage.removeItem("FilterItems-report");

    setFilters({
      status: "",
      dateRange: "",
      tags: "",
      projectManager: "",
      start_date: "",
      end_date: "",
    });
    setSearchTerm("");
    setDebouncedSearchTerm("");

    sessionStorage.removeItem("currentPage-report");

    dispatch(ReportCSV({ page: currentPage }));
  };

  const handleAddTags = (data) => {
    const obj = {
      tag: data,
    };
    dispatch(AddTags(obj));
  };

  useEffect(() => {
    if (AddTagsSuccess === true) {
      dispatch(Tags(""));
      dispatch(ResetSuccessUpdate());
    }
  }, [AddTagsSuccess]);

  const handleClearFil = () => {
    sessionStorage.removeItem("dateRange-report");
    sessionStorage.removeItem("Custom_end_date_report");
    sessionStorage.removeItem("Custom_start_date_report");
  };

  const handleSelectDataCustom = (data, key, name) => {
    sessionStorage.setItem(key, data);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name === "Custom_start_date_report" ? "start_date" : "end_date"]: data,
    }));
  };

  const JobDataPopupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        JobDataPopupRef.current &&
        !JobDataPopupRef.current.contains(event.target)
      ) {
        dispatch(InactiveJobPopup());
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const exportToExcel = () => {
    if (ListDataMain.length === 0) {
      alert("No data available to export.");
      return;
    }

    const data = ListDataMain.map((item) => ({
      "Project Manager": item.project_manager || "-",
      "% Progress": item.percent_complete
        ? `${item.percent_complete.toFixed(1)}%`
        : "-",
      WIP: item.wip ? numeral(item.wip).format("$0,0") : "-",
      Site: item.site.site_name || "-",
      "Labour H Target": item.target_labour_hour
        ? `${item.target_labour_hour.toFixed(0)}H`
        : "-",
      "Labour H Actual": item.actual_labour_hour
        ? `${item.actual_labour_hour.toFixed(0)}H`
        : "-",
      "% Var": item.var_total ?item.var_total : "-",
      "Labour H Target M": item.target_labour_hour_month
        ? `${item.target_labour_hour_month.toFixed(0)}H`
        : "-",
      "Labour H Actual M": item.actual_labour_hour_month
        ? `${item.actual_labour_hour_month.toFixed(0)}H`
        : "-",
      "% Var M": item.var_total_month
        ? item.var_total_month
        : "-",
      Client: item.client || "-",
      Job: item.job_id || "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    XLSX.writeFile(workbook, "ReportData.xlsx");
  };

  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({ contentRef });

  const defaultColumnWidths = {
    projectManager: 200,
    progress: 100,
    wip: 100,
    site: 200,
    labourHTarget: 150,
    labourHActual: 150,
    varTotal: 100,
    labourHTargetM: 150,
    labourHActualM: 150,
    varTotalMonth: 100,
    client: 200,
    job: 100,
  };

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedWidths = sessionStorage.getItem("columnWidths");
    return savedWidths ? JSON.parse(savedWidths) : defaultColumnWidths;
  });

  useEffect(() => {
    sessionStorage.setItem("columnWidths", JSON.stringify(columnWidths));
  }, [columnWidths]);

  const handleResize =
    (key) =>
    (e, { size }) => {
      setColumnWidths((prevWidths) => ({
        ...prevWidths,
        [key]: size.width,
      }));
    };

  const [SelectedReport, setSelectedReport] = useState([]);

  const handleSelectReport = (id) => {

    setSelectedReport((prev) =>
      prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
    );
  };

  const handleRemoveReport = () => {
    if (SelectedReport.length === 0) return;

    const updatedData = ListDataMain.filter(
      (item) => !SelectedReport.includes(item.job_id)
    );
    sessionStorage.setItem("SelectedReportRemove", JSON.stringify(updatedData));
    setSelectedReportRemove(updatedData);
    setSelectedReport([]);
  };

  // useEffect(() => {
  //   const timerID = setInterval(() => {
  //     console.log("hi");

  //   }, 1000);
  //   return () => clearInterval(timerID); // Cleanup function
  // }, [filters.status]);

  const [isOpenJob, setIsOpenJob] = useState(false);
  const openPopupJob = () => {
    setIsOpenJob(true);
  };
  const closePopupJob = () => {
    setIsOpenJob(false);
  };

  const handleFilter = (filterData) => {
    sessionStorage.removeItem("searchTerm-report");
    setSearchTerm("");
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...filterData };
     
      Object.keys(updatedFilters).forEach((key) => {
        const value = updatedFilters[key];
        if (value) {
          if (typeof value === "object") {
            sessionStorage.setItem(key, JSON.stringify(value));
          } else {
            sessionStorage.setItem(key, value);
          }
        } else {
          sessionStorage.removeItem(key);
        }
      });

      return updatedFilters;
    });
  };

  const FilterItems = JSON.parse(sessionStorage.getItem("FilterItems-report"));
  const flattenedValues =
    FilterItems &&
    Object.entries(FilterItems).flatMap(([key, value]) =>
      Array.isArray(value)
        ? value.map((v) => ({ key, value: v }))
        : [{ key, value }]
    );

  return (
    <div className="NewJob">
      <Toaster />
      <div className="FilterTable">
        <div className="FilterItemDiv">
          <Filter
            name="Status"
            options={["TBC", "WIP*", "WIP", "DONE"]}
            value={filters.status}
            onChange={(value) =>
              handleFilterChange("status-report", "status", value)
            }
          />
          <Filter
            name="Date Range"
            options={["Custom"]}
            value={filters.dateRange}
            isDateFilter={true}
            handleSelectData={handleSelectData}
            onChange={(value) =>
              handleFilterChange("dateRange-report", "dateRange", value)
            }
            handleOpenDateRange={handleDatePopup}
          />
          {/* <Filter
            name="Tag Selector"
            options={TagsData}
            value={filters.tags}
            onChange={(value) =>
              handleFilterChange("tags-report", "tags", value)
            }
            isTags={true}
            handleAddTags={handleAddTags}
          /> */}
          <Filter
            name="Project Manager"
            options={ProjectManagersData}
            value={filters.projectManager}
            onChange={(value) =>
              handleFilterChange(
                "projectManager-report",
                "projectManager",
                value
              )
            }
            classname="ProjectManager"
          />
          {filters.dateRange ||
          filters.status ||
          filters.projectManager ||
          filters.end_date ||
          filters.start_date ||
          filters.tags ? (
            <div className="Filters ClearFilter" onClick={handleClearFilter}>
              Clear <span style={{ marginLeft: "10px" }}>&#10005;</span>
            </div>
          ) : (
            ""
          )}
          <input
            className="SearchJobs"
            type="text"
            placeholder="Search reports..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="ExportDiv">
          <div
            onClick={exportToExcel}
            className="export"
            style={{ gap: "10px" }}
            title="Export"
          >
            <svg
              fill="currentcolor"
              height="20px"
              width="20px"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 493.52 493.52"
              stroke="currentcolor"
              stroke-width="0.00493525"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke="#CCCCCC"
                stroke-width="0.98705"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g id="XMLID_30_">
                  <path
                    id="XMLID_32_"
                    d="M430.557,79.556H218.44c21.622,12.688,40.255,29.729,54.859,49.906h157.258 c7.196,0,13.063,5.863,13.063,13.06v238.662c0,7.199-5.866,13.064-13.063,13.064H191.894c-7.198,0-13.062-5.865-13.062-13.064 V222.173c-6.027-3.1-12.33-5.715-18.845-7.732c-3.818,11.764-12.105,21.787-23.508,27.781c-2.39,1.252-4.987,2.014-7.554,2.844 v136.119c0,34.717,28.25,62.971,62.968,62.971h238.663c34.718,0,62.969-28.254,62.969-62.971V142.522 C493.525,107.806,465.275,79.556,430.557,79.556z"
                  ></path>
                  <path
                    id="XMLID_31_"
                    d="M129.037,175.989c51.419,1.234,96.388,28.283,122.25,68.865c2.371,3.705,6.434,5.848,10.657,5.848 c1.152,0,2.322-0.162,3.46-0.486c5.377-1.545,9.114-6.418,9.179-12.006c0-0.504,0-1.01,0-1.51 c0-81.148-64.853-147.023-145.527-148.957V64.155c0-5.492-3.038-10.512-7.879-13.078c-2.16-1.139-4.533-1.707-6.889-1.707 c-2.94,0-5.848,0.88-8.35,2.584L5.751,120.526C2.162,122.98,0.018,127.041,0,131.394c-0.017,4.338,2.113,8.418,5.687,10.902 l100.17,69.451c2.518,1.753,5.459,2.631,8.414,2.631c2.355,0,4.696-0.553,6.857-1.676c4.855-2.549,7.909-7.6,7.909-13.092V175.989z "
                  ></path>
                </g>
              </g>
            </svg>
            <span style={{ fontWeight: "600" }}>Export</span>
          </div>
          <div onClick={reactToPrintFn} className="export" title="Print">
            <svg
              fill="currentcolor"
              height="20px"
              width="20px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              enable-background="new 0 0 64 64"
              stroke="currentcolor"
              stroke-width="1.8559999999999999"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g id="Printer">
                  <path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002 C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995 c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507 c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649 c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012 C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"></path>
                  <path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"></path>
                  <path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"></path>
                  <circle cx="7.9590998" cy="21.8405495" r="2"></circle>
                  <circle cx="14.2856998" cy="21.8405495" r="2"></circle>
                  <circle cx="20.6121998" cy="21.8405495" r="2"></circle>
                  <path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z"></path>
                </g>
              </g>
            </svg>
            <span style={{ fontWeight: "600" }}>Print</span>
          </div>
        </div>


        {OpenPopup && (
          <div className="AddJobForm">
            <DateRangeFilter
              Data={filters.dateRange}
              ref={JobPopupRef}
              handleSelectData={handleSelectData}
              handleSelectDataCustom={handleSelectDataCustom}
              handleClearFil={handleClearFil}
              customStartKey="Custom_start_date_report"
              customEndKey="Custom_end_date_report"
              customStartDate={filters.custom_start_date}
              customEndDate={filters.custom_end_date}
            />
            ;
          </div>
        )}
      </div>

      {/* {isOpenJob && (
        <JobsFilter
          closePopup={closePopupJob}
          onFilter={handleFilter}
          handleClearFilter={handleClearFilter}
          handleAddTags={handleAddTags}
          filters={filters}
          setFilters={setFilters}
          storageKeys={storageKeys}
          FilterItems={FilterItems}
        />
      )} */}
      <div
        className="JobTable table-container-print print-content"
        ref={contentRef}
      >
        <table
          className="table-container-print-table"
          style={{ minWidth: "100%" }}
        >
          <thead>
            {/* <tr>
              <th  className="ReportCheckBox"></th>

              <th>Project Manager</th>
              <th>% Progress</th>

              <th>WIP</th>
              <th>Site</th>
              <th>Labour H Target</th>
              <th>Labour H Actual</th>
              <th>% Var</th>
              <th>Labour H Target M</th>
              <th>Labour H Actual M</th>
              <th>% Var</th>
              <th>Client</th>
              <th>Job</th>
       
            </tr> */}
            <tr>
              {/* <th className="ReportCheckBox"></th> */}
              <ResizableColumn
                width={columnWidths.projectManager}
                onResize={handleResize("projectManager")}
              >
                Project Manager
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.progress}
                onResize={handleResize("progress")}
              >
                % Progress
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.wip}
                onResize={handleResize("wip")}
              >
                WIP
              </ResizableColumn>

              <ResizableColumn
                width={columnWidths.site}
                onResize={handleResize("site")}
              >
                Site
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.labourHTarget}
                onResize={handleResize("labourHTarget")}
              >
                Labour H Target
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.labourHActual}
                onResize={handleResize("labourHActual")}
              >
                Labour H Actual
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.varTotal}
                onResize={handleResize("varTotal")}
              >
                % Var
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.labourHTargetM}
                onResize={handleResize("labourHTargetM")}
              >
                Labour H Target M
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.labourHActualM}
                onResize={handleResize("labourHActualM")}
              >
                Labour H Actual M
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.varTotalMonth}
                onResize={handleResize("varTotalMonth")}
              >
                % Var
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.client}
                onResize={handleResize("client")}
              >
                Client
              </ResizableColumn>
              <ResizableColumn
                width={columnWidths.job}
                onResize={handleResize("job")}
              >
                Job
              </ResizableColumn>
            </tr>
          </thead>
          <tbody>
            {ListDataMain.length > 0 ? (
              ListDataMain.map((x, i) => (
                <tr key={i} onClick={() => handleSelectReport(x.job_id)}>
                  {/* <td className="ReportCheckBox">
                    <div
                      className={`Filter-checkbox ${
                        SelectedReport.includes(x.job_id) && "Active"
                      }`}
                    ></div>
                  </td> */}
                  <td style={{ minWidth: "200px" }}>
                    {x.project_manager ? x.project_manager : "-"}
                  </td>
                  <td>
                    {x.percent_complete
                      ? `${x.percent_complete.toFixed(1)}%`
                      : "-"}
                  </td>
                  <td>{x.wip ? numeral(x.wip).format("$0,0") : "-"}</td>
                  <td style={{ minWidth: "200px" }}>
                    {x.site.site_name ? x.site.site_name : "-"}
                  </td>

                  <td>
                    {x.target_labour_hour
                      ? `${x.target_labour_hour.toFixed(0)}H`
                      : "-"}
                  </td>
                  <td>
                    {x.actual_labour_hour
                      ? `${x.actual_labour_hour.toFixed(0)}H`
                      : "-"}
                  </td>
                  <td>{x.var_total ? PercentColor(x.var_total) : "-"}</td>
                  <td>
                    {x.target_labour_hour_month
                      ? `${x.target_labour_hour_month.toFixed(0)}H`
                      : "-"}
                  </td>
                  <td>
                    {x.actual_labour_hour_month
                      ? `${x.actual_labour_hour_month.toFixed(0)}H`
                      : "-"}
                  </td>
                  <td>
                    {x.var_total_month ? PercentColor(x.var_total_month) : "-"}
                  </td>
                  <td style={{ minWidth: "200px" }}>
                    {x.client ? x.client : "-"}
                  </td>
                  <td>{x.job_id ? x.job_id : "-"}</td>
                </tr>
              ))
            ) : (
              <div className="ItemHead">No Data</div>
            )}
          </tbody>
        </table>
      </div>
      {!hasEmptyValue && !searchTerm && (
        <div className="JobPagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            if (
              page === 1 ||
              page === totalPages ||
              page === currentPage ||
              page === currentPage - 1 ||
              page === currentPage + 1
            ) {
              return (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  style={{
                    margin: "0 5px",
                    backgroundColor: currentPage === page ? "blue" : "white",
                    color: currentPage === page ? "white" : "black",
                    border: "1px solid #888888",
                    padding: "5px 10px",
                  }}
                >
                  {page}
                </button>
              );
            }
            if (
              (page === currentPage - 2 && currentPage > 3) ||
              (page === currentPage + 2 && currentPage < totalPages - 2)
            ) {
              return (
                <span key={page} style={{ margin: "0 5px" }}>
                  ...
                </span>
              );
            }
            return null;
          })}

          {/* {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            return (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                style={{
                  margin: "0 5px",
                  backgroundColor: currentPage === page ? "blue" : "white",
                  color: currentPage === page ? "white" : "black",
                  border: "1px solid #888888",
                  padding: "5px 10px",
                }}
              >
                {page}
              </button>
            );
          })} */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default ReportsNew;
