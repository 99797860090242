import axios from 'axios';

// Define base URLs for development and production
const devBaseUrl = 'https://quotech-uat-api.bravadagroup.com.au';
const prodBaseUrl = 'https://quotech-api.finsoeasy.com';

// Choose the correct base URL based on the environment
const BASE_URL = process.env.NODE_ENV === 'production' ? prodBaseUrl : devBaseUrl;

// Create an Axios instance with the chosen base URL and default headers
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to attach an authorization token if available
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('IdToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Optional response interceptor to handle global errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default axiosInstance;
