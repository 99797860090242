import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { formatDateRange } from "../utils/utils";

const JobsFilter = ({
  closePopup,
  onFilter,
  handleClearFilter,
  filters,
  storageKeys,
  setFilters,
  FilterItems,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(filters.status || "");
  const [selectedOption, setSelectedOption] = useState(
    filters.projectManager || ""
  );
  const [selectedTags, setSelectedTags] = useState(filters.tags || "");
  const [selectedDate, setSelectedDate] = useState(filters.dateRange || "");
  const [selectedStartDate, setSelectedStartDate] = useState(
    filters.custom_start_date || ""
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    filters.custom_end_date || ""
  );

  useEffect(() => {
    setSelectedStatus(filters.status || "");
    setSelectedOption(filters.projectManager || "");
    setSelectedTags(filters.tags || "");
    setSelectedDate(filters.dateRange || "");
    setSelectedStartDate(filters.custom_start_date || "");
    setSelectedEndDate(filters.custom_end_date || "");
  }, [filters]);

  const { TagsData, ProjectManagersData } = useSelector(
    (state) => state.allapi
  );

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      closePopup();
    }
  };

  const handleStatusClick = (status) => {
    setSelectedStatus((prevStatus) => (prevStatus === status ? "" : status));
  };

  const handleFilter = () => {
    const filters = {
      status: selectedStatus || "",
      projectManager: selectedOption || "",
      tags: selectedTags || "",
      dateRange: selectedDate || "",
      custom_start_date:selectedStartDate||"",
      custom_end_date: selectedEndDate|| ""
    };



    if (filters.status)
      sessionStorage.setItem(storageKeys.status, filters.status);
    if (filters.projectManager)
      sessionStorage.setItem(
        storageKeys.projectManager,
        filters.projectManager
      );
    if (filters.tags)
      sessionStorage.setItem(storageKeys.tags, JSON.stringify(filters.tags));
    if (filters.dateRange)
      sessionStorage.setItem(storageKeys.dateRange, filters.dateRange);
    if (filters.custom_start_date)
      sessionStorage.setItem(storageKeys.custom_start_date, filters.custom_start_date);
    if (filters.custom_start_date)
      sessionStorage.setItem(storageKeys.custom_start_date, filters.custom_start_date);

    onFilter(filters);
    closePopup();
  };

  const handleSelectTag = (option) => {
    if (!option?.tag) return;

    setSelectedTags((prevTags) => {
      const updatedTags = prevTags.includes(option.tag)
        ? prevTags.filter((tag) => tag !== option.tag)
        : [...prevTags, option.tag];

      return updatedTags.filter(Boolean);
    });
  };
  const handleSelectDate = (data) => {
    setSelectedDate((prevStatus) => (prevStatus === data ? "" : data));

  };

  

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.target.value = "";
    }
  };

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  const [isInputVisible, setIsInputVisible] = useState(false);

  const handleButtonClick = () => {
    setIsInputVisible(true);
  };

  const handleInputBlur = () => {
    setIsInputVisible(false);
  };

  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const [isOpenManager, setIsOpenManager] = useState(false);

  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpenManager(false);
  };

  const handleClick = (key, value) => {
    setFilters((prevFilters) => {
      let updatedFilters = { ...prevFilters };

      if (key === "tags") {
        const updatedTags = prevFilters.tags.filter((tag) => tag !== value);
        if (updatedTags.length > 0) {
          updatedFilters.tags = updatedTags;
        } else {
          delete updatedFilters.tags;
        }
      } else {
        delete updatedFilters[key];
      }

      const cleanedFilters = Object.fromEntries(
        Object.entries(updatedFilters).filter(
          ([_, v]) => v !== "" && v !== undefined
        )
      );

      setTimeout(() => {
        sessionStorage.setItem("FilterItems", JSON.stringify(cleanedFilters));

        if (cleanedFilters[key] !== undefined) {
          sessionStorage.setItem(
            storageKeys[key],
            JSON.stringify(cleanedFilters[key])
          );
        } else {
          sessionStorage.removeItem(storageKeys[key]);
        }
      }, 0);

      return cleanedFilters;
    });
  };

  const flattenedValues =
  FilterItems &&
  Object.entries(FilterItems).flatMap(([key, value]) => {
    if (key === "custom_start_date" || key === "custom_end_date") {
      return [];
    }
    return Array.isArray(value)
      ? value.map((v) => ({ key, value: v }))
      : [{ key, value }];
  });

if (FilterItems && FilterItems.custom_start_date && FilterItems.custom_end_date) {
  flattenedValues.push({
    key: "custom_date_range",
    value: `${FilterItems.custom_start_date} to ${FilterItems.custom_end_date}`,
  });
}


  const capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="JobsFilter">
      <div className="popup-overlay" onClick={handleOverlayClick}>
        <div className="popup-content">
          <div className="First flex" style={{ position: "relative" }}>
            <button className="popup-close" onClick={closePopup}>
              x
            </button>
            <div className="Heading w-full flex justify-center clearall">
              Filter
            </div>
          </div>
          <div className="Content">
            {FilterItems && Object.keys(FilterItems).length > 0 && (
              <div className="Selected">
                <div className="Section">Selected</div>
                <div className="SelectedItems">
                  {flattenedValues.map((entry, index) => (
                    <div
                      key={index}
                      className="statusoption"
                      onClick={() => handleClick(entry.key, entry.value)}
                    >
                      {capitalizeWords(entry.value).replace("_", " ")}
                      <div className="clrdelete">X</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* {FilterItems && (
              <div className="Selected">
                <div className="Section">Selected</div>
                <div className="SelectedItems">
                  {Object.entries(FilterItems).map(([key, value]) => (
                    <div
                      className="statusoption"
                      key={key}
                      onClick={() => handleClick(key)}
                    >
                      <div>{key === "tags" ? value.join(", ") : value}</div>
                      <div className="clrdelete">X</div>
                    </div>
                  ))}
                </div>
              </div>
            )} */}

            <div className="Section">Status</div>
            <div className="flex statusoptionalign">
              <div
                className={`statusoption ${
                  selectedStatus === "wip" ? "active" : ""
                }`}
                onClick={() => handleStatusClick("wip")}
              >
                wip
              </div>
              <div
                className={`statusoption ${
                  selectedStatus === "wip*" ? "active" : ""
                }`}
                onClick={() => handleStatusClick("wip*")}
              >
                wip*
              </div>
              <div
                className={`statusoption ${
                  selectedStatus === "tbc" ? "active" : ""
                }`}
                onClick={() => handleStatusClick("tbc")}
              >
                tbc
              </div>
              <div
                className={`statusoption ${
                  selectedStatus === "done" ? "active" : ""
                }`}
                onClick={() => handleStatusClick("done")}
              >
                done
              </div>
            </div>
            <div className="InputDivDividerstatus"></div>
            <div className="Section w-full">Project Manager</div>
            <div className="dropdown" ref={dropdownRef}>
              <div
                className="dropdown-header"
                onClick={() => setIsOpenManager(!isOpenManager)}
              >
                {selectedOption ? selectedOption : "Select"}
                <span className="arrow">{isOpenManager ? "▲" : "▼"}</span>
              </div>
              {isOpenManager && (
                <div className="dropdown-list ">
                  {ProjectManagersData?.map((x, i) => (
                    <div
                      key={i}
                      className="dropdown-item checkbox-container"
                      onClick={() => handleSelect(x)}
                    >
                      {/* <div
                        className={`checkbox ${
                          selectedOption.includes(x) ? "selected" : ""
                        }`}
                      ></div> */}
                      <div
                    className={`Filter-checkbox ${
                      selectedOption.includes(x) ? "Active" : ""
                    }`}
                  ></div>
                      {x}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="InputDivDividerstatus"></div>
            <div className="Section w-full">Tag Selector</div>
            {TagsData.map((x, i) => {
              return (
                <div
                  className="flex tagselectalign checkbox-container"
                  onClick={(x) => {
                    toggleCheckbox();
                    handleSelectTag(x);
                  }}
                >
                  <div
                    className={`Filter-checkbox  ${
                      selectedTags.includes(x.tag) ? "Active" : ""
                    }`}
                  ></div>
                  <div
                    key={x.id}
                    className={`tagselector ${
                      selectedTags.includes(x.tag) ? "selected" : ""
                    }`}
                    onClick={() => handleSelectTag(x)}
                  >
                    {x.tag}
                  </div>
                </div>
              );
            })}

            <div className="Options">
              {!isInputVisible ? (
                <button className="create-button" onClick={handleButtonClick}>
                  Create New...
                </button>
              ) : (
                <input
                  type="text"
                  className="create-input"
                  placeholder="Create new..."
                  onKeyPress={handleInputKeyPress}
                  onBlur={handleInputBlur}
                  autoFocus
                />
              )}
            </div>
            <div
              className={`date-range-container ${expanded ? "expanded" : ""}`}
            >
              <div className="Section w-full">Date Range</div>
              <div
                // className="flex daterange active"
                className={`flex daterange  ${
                  selectedDate === "last_week" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("last_week")}
              >
                <div className="Head">Last week</div>
                <div className="Head">{formatDateRange("last_week")}</div>
              </div>
              <div className="InputDivDivider"></div>
              <div
                className={`flex daterange  ${
                  selectedDate === "this_month" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("this_month")}
              >
                <div className="Head">This month</div>
                <div className="Head">{formatDateRange("this_month")}</div>
              </div>
              <div className="InputDivDivider"></div>

              <div
                className={`flex daterange  ${
                  selectedDate === "this_quarter" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("this_quarter")}
              >
                <div className="Head">This quarter</div>
                <div className="Head">{formatDateRange("this_quarter")}</div>
              </div>
              <div className="InputDivDivider"></div>

              <div
                className={`flex daterange  ${
                  selectedDate === "this_financial_year" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("this_financial_year")}
              >
                <div className="Head">This financial year</div>
                <div className="Head">
                  {formatDateRange("this_financial_year")}
                </div>
              </div>
              <div className="InputDivDivider"></div>
              <div
                className={`flex daterange  ${
                  selectedDate === "last_financial_year" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("last_financial_year")}
              >
                <div className="Head">Last financial year</div>
                <div className="Head">
                  {formatDateRange("last_financial_year")}
                </div>
              </div>

              <div className="InputDivDivider"></div>
              <div
                className={`flex daterange  ${
                  selectedDate === "month_to_date" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("month_to_date")}
              >
                <div className="Head">Month to date</div>
                <div className="Head">{formatDateRange("month_to_date")}</div>
              </div>
              <div className="InputDivDivider"></div>

              <div
                className={`flex daterange  ${
                  selectedDate === "quarter_to_date" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("quarter_to_date")}
              >
                <div className="Head">Quarter to date</div>
                <div className="Head">{formatDateRange("quarter_to_date")}</div>
              </div>
              <div className="InputDivDivider"></div>

              <div
                className={`flex daterange  ${
                  selectedDate === "year_to_date" ? "active" : ""
                }`}
                onClick={() => handleSelectDate("year_to_date")}
              >
                <div className="Head">Year to date</div>
                <div className="Head">{formatDateRange("year_to_date")}</div>
              </div>
              <div className="InputDivDivider"></div>
              <div className="flex">
                <div className="date-picker-wrapper daterange">
                  <label for="date-input" className="Head">
                    Start Date
                  </label>
                  <input
                    type="date"
                    id="date-input"
                    name="date-input"
                    class="date-input"
                    value={selectedStartDate}
                    onChange={(e) => setSelectedStartDate(e.target.value)}
                  />
                </div>
                <div className="date-picker-wrapper daterange">
                  <label for="date-input" className="Head">
                    End Date
                  </label>
                  <input
                    type="date"
                    id="date-input"
                    name="date-input"
                    class="date-input"
                    value={selectedEndDate}
                    onChange={(e) => setSelectedEndDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button className="toggle-button" onClick={toggleExpand}>
              {expanded ? "Show Less" : "Show More"}
            </button>
          </div>
          <div className="Second">
            <button className="clearall" onClick={handleClearFilter}>
              Clear all
            </button>
            <button className="filterupload" onClick={handleFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsFilter;
