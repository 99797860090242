import numeral from "numeral";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ApproveVariationKPI,
  DeleteVar,
  GetLabourStat,
  ResetSuccessUpdate,
  VariationKPIList,
} from "../../../reducers/AllApis";
import toast, { Toaster } from "react-hot-toast";
import { DateForm } from "./../../../utils/utils";
import AddVariation from "../AddVariation";
import { CircularProgress } from "@mui/material";

const Variation = ({
  Job_Actual,
  VariationKPIJoblastupdated,
  VariationKPIJob,
  VariationKPIJobData,
  quoteid,
  jobid,
}) => {
  const dispatch = useDispatch();
  const [editJobData, setEditJobData] = useState(null);
  const {
    ApproveVariationKPISuccess,
    ApproveVariationKPIData,
    ApproveVariationKPIErr,
    DeleteVarSuccess,
  } = useSelector((state) => state.allapi);

  const handleApprove = (approve, QuoteId) => {
    const obj = {
      approve: approve,
      QuoteId: QuoteId,
    };

    dispatch(ApproveVariationKPI(obj));
  };

  useEffect(() => {
    if (ApproveVariationKPISuccess === true) {
      dispatch(ResetSuccessUpdate());
      dispatch(GetLabourStat(jobid))
      dispatch(VariationKPIList(VariationKPIJob.job_id));
    } else if (ApproveVariationKPISuccess === "Failed") {
    }
    if (DeleteVarSuccess === true) {
      dispatch(ResetSuccessUpdate());
      dispatch(VariationKPIList(VariationKPIJob.job_id));
    }
  }, [ApproveVariationKPISuccess, DeleteVarSuccess]);

  const sumOfFields =
    VariationKPIJobData &&
    VariationKPIJobData.reduce((acc, item) => {
      for (let key in item) {
        if (typeof item[key] === "number") {
          acc[key] = (acc[key] || 0) + item[key];
        }
      }
      return acc;
    }, {});

  const [ShowForm, setShowForm] = useState(false);

  const handleAddVar = () => {
    setEditJobData(null);
    setShowForm(true);
  };
  const handleEditJob = (data) => {
    setEditJobData(data);
    setShowForm(true);
  };

  const handleClose = () => {
    setShowForm(false);
    setEditJobData(null);
  };

  const [showModal, setShowModal] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const countdownTimer = useRef(null);
  const [isDeletingId, setIsDeletingId] = useState(null);

  const handleConfirm = (x) => {
    if (countdownTimer.current) {
      clearInterval(countdownTimer.current);
    }

    setIsDeletingId(x.id);
    setIsDeleting(true);
    setCountdown(10);
    setShowModal(null);

    countdownTimer.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownTimer.current);
          dispatch(DeleteVar(x));
          setIsDeleting(false);
          setIsDeletingId(null);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleUndo = () => {
    clearInterval(countdownTimer.current);
    setIsDeleting(false);
    setIsDeletingId(null);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  return (
    <>
      <Toaster />
      <div className="KPISummarytext">
        JOB KPI Summary - Contract Value Plus Variations
      </div>

      <div className="flex justify-between variation">
        <div className="labourcard StatCard" style={{ width: "270px" }}>
          <div className="flex" style={{ gap: "30px" }}>
            <div style={{ gap: "33px" }}>
              <div style={{ gap: "10px", marginBottom: "10px" }}>
                <div className="cardTitle">Client Name:</div>
                <div className="cardcontent">
                  {VariationKPIJob && VariationKPIJob.client
                    ? VariationKPIJob.client
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="flex" style={{ gap: "30px" }}>
            <div style={{ gap: "10px", marginBottom: "10px" }}>
              <div className="cardTitle">simPRO Job Number:</div>
              <div className="cardcontent">
                {VariationKPIJob && VariationKPIJob.job_id
                  ? VariationKPIJob.job_id
                  : "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="labourcard StatCard" style={{ width: "270px" }}>
          <div className="cardHead">Last Updated:</div>
          <div className="flex" style={{ gap: "30px" }}>
            <div style={{ gap: "33px" }}>
              <div style={{ gap: "10px", marginBottom: "10px" }}>
                <div className="cardTitle">By:</div>
                <div className="cardcontent">-</div>
              </div>
            </div>
            <div style={{ gap: "33px" }}>
              <div style={{ gap: "10px", marginBottom: "10px" }}>
                <div className="cardTitle">Date:</div>
                <div className="cardcontent">
                  {VariationKPIJoblastupdated
                    ? DateForm(VariationKPIJoblastupdated)
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="LabourAnalysis labourcard ">
        <div className="AddButton" onClick={handleAddVar}>
          + Add variation
        </div>
        {ShowForm && (
          <div className="AddJobForm">
            <AddVariation
              handleClose={handleClose}
              jobid={jobid}
              quoteid={quoteid}
              initialData={editJobData}
            />
          </div>
        )}

        <div className="TableNew TableNewVariation">
          <div className="TableFieldNames">
            <div className="TableStaticHeading">
              <div className="Item fixedheightheading"></div>
              {/* <div className="Item">Original Quote as per Contract Value</div> */}
              {VariationKPIJobData &&
                VariationKPIJobData.length > 0 &&
                VariationKPIJobData.map((x, i) => {
                  return (
                    <div key={i} className="Item subitem">
                      Variation # {i + 1}
                    </div>
                  );
                })}

              <div className="Item divider"></div>
              <div className="Item">Revised Contract Value</div>
              <div className="Item divider"></div>
            </div>
          </div>
          <div className="TableFieldData">
            <div className="DataTable Heading fixedheightheading">
              <div className="Item Total">Quote Numbers</div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">Quote Value</div>
              </div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">Labour</div>
              </div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">Products</div>
              </div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">Plant Rental & Equip</div>
              </div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">Contract Services</div>
              </div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">Other Charges</div>
              </div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">Gross Margin</div>
              </div>
              <div className=""></div>
              <div className="Item">
                <div className="Second">TOTALS</div>
              </div>
              <div className="Item">
                <div className="Second">Budgeted Hours</div>
              </div>
              <div className="Item">
                <div className="Second">Approval</div>
              </div>
              <div className="Item">
                <div className="Second">Action</div>
              </div>
              <div className="dividertable"></div>
            </div>
            {/* <div
              className="DataTable VariationKPIJobDatarow"
              style={{ gap: "27px", alignItems: "center" }}
            >
              <div className="Item Total"></div>
              <div className="Item"></div>
              <div className="Item">{Job_Actual ? Job_Actual.labour_cost : "-"}</div>
              <div className="Item">{Job_Actual && Job_Actual.product_cost ? Job_Actual.product_cost : "-"}</div>
              <div className="Item"></div>
              <div className="Item">{Job_Actual && Job_Actual.contractor_cost ? Job_Actual.contractor_cost : "-"}</div>
              <div className="Item">{Job_Actual && Job_Actual.other_cost  ? Job_Actual.other_cost : "-"}</div>
              <div className="Item"></div>
              <div className="Item"></div>
              <div className="Item">{Job_Actual && Job_Actual.labour_hours  ? `${(Job_Actual.labour_hours).toFixed(0)}H` : "-"}</div>
              <div className="Item"></div>
              <div className="Item"></div>
            </div> */}

            {VariationKPIJobData && VariationKPIJobData.length > 0 ? (
              <>
                {VariationKPIJobData.map((x, i) => {
                  return (
                    <div
                      key={i}
                      className="DataTable VariationKPIJobDatarow"
                      style={{ gap: "27px", alignItems: "center" }}
                    >
                      <div className="Item Total">
                        {x.quote_number ? x.quote_number : "-"}{" "}
                      </div>
                      <div className="Item">
                        {x.revenue ? numeral(x.revenue).format("$0,0") : "-"}
                      </div>
                      <div className="Item">
                        {x.labour_est_revenue
                          ? numeral(x.labour_est_revenue).format("$0,0")
                          : "-"}
                      </div>
                      <div className="Item">
                        {x.product_est_revenue
                          ? numeral(x.product_est_revenue).format("$0,0")
                          : "-"}
                      </div>
                      <div className="Item">
                        {x.plant_rental_and_equip_est_revenue
                          ? numeral(
                              x.plant_rental_and_equip_est_revenue
                            ).format("$0,0")
                          : "-"}
                      </div>
                      <div className="Item">
                        {x.sub_contract_est_revenue
                          ? numeral(x.sub_contract_est_revenue).format("$0,0")
                          : "-"}
                      </div>
                      <div className="Item">
                        {x.other_charges_charge_out
                          ? numeral(x.other_charges_charge_out).format("$0,0")
                          : "-"}
                      </div>
                      <div className="Item">
                        {x.margin ? numeral(x.margin).format("$0,0") : "-"}
                      </div>
                      <div className="Item">
                        {x.revenue ? numeral(x.revenue).format("$0,0") : "-"}
                      </div>
                      <div className="Item">
                        {x.est_labour_hours_total
                          ? `${x.est_labour_hours_total}H`
                          : "-"}
                      </div>

                      <div className="Item flex" style={{ gap: "10px" }}>
                        <button
                          disabled={x.status.toLowerCase() === "approved"}
                          onClick={() => handleApprove(true, x.quote_number)}
                          className="approved"
                          style={{ background: "#d7f6e0" }}
                        >
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAg0lEQVR4nGNgGBnA9LpOhsUVLSEyNOo2ml3X/WF+XduBdI03dH+aXNfzpa5Gm0u6gvb35TlwOdXkhq4nHtN1Npje0N2JbABRGkHA+IqWnOl1nXumN3R3WTyy4CTZj2Y39BRNb+g+MLuue5coGxnQAMgFZtd1r5McqjBgv9+ehSyNdAMADXVBVLP05FAAAAAASUVORK5CYII="
                            alt="checkmark--v1"
                          />
                        </button>
                        <button
                          disabled={x.status.toLowerCase() === "rejected"}
                          onClick={() => handleApprove(false, x.quote_number)}
                          className="approved"
                          style={{ background: "#ff435a52" }}
                        >
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgUlEQVR4nN2RMQoCQQxFcw0b7/QSRwtBxc4LCTZ7ShH/NCMzsIrN6q7V+rr/yU9IYvYfFFiM8d8KBFfB7uWaKWJd/VtKSxtC7huB5H5oGpIgZ/fTYPDZALYtAJfWKOL4VbBHcM7uJbt3NgZNnax+54h91XdYVf1x5/LrtcvUP8+HB/+dV8U+bj5aAAAAAElFTkSuQmCC"
                            alt="multiply"
                          />
                        </button>
                      </div>

                      <div className="Item flex" style={{ gap: "10px" }}>
                        <button
                          onClick={() => handleEditJob(x)}
                          className="approved"
                          style={{
                            background: "var(--edit-job-bg) !important",
                          }}
                          title="Edit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0,0,256,256"
                            width="15px"
                            height="15px"
                            fill-rule="nonzero"
                          >
                            <g
                              fill="#ffffff"
                              fill-rule="nonzero"
                              stroke="none"
                              stroke-width="1"
                              stroke-linecap="butt"
                              stroke-linejoin="miter"
                              stroke-miterlimit="10"
                              stroke-dasharray=""
                              stroke-dashoffset="0"
                              font-family="none"
                              font-weight="none"
                              font-size="none"
                              text-anchor="none"
                            >
                              <g transform="scale(5.12,5.12)">
                                <path d="M43.125,2c-1.24609,0 -2.48828,0.48828 -3.4375,1.4375l-0.8125,0.8125l6.875,6.875c-0.00391,0.00391 0.8125,-0.8125 0.8125,-0.8125c1.90234,-1.90234 1.89844,-4.97656 0,-6.875c-0.95312,-0.94922 -2.19141,-1.4375 -3.4375,-1.4375zM37.34375,6.03125c-0.22656,0.03125 -0.4375,0.14453 -0.59375,0.3125l-32.4375,32.46875c-0.12891,0.11719 -0.22656,0.26953 -0.28125,0.4375l-2,7.5c-0.08984,0.34375 0.01172,0.70703 0.26172,0.95703c0.25,0.25 0.61328,0.35156 0.95703,0.26172l7.5,-2c0.16797,-0.05469 0.32031,-0.15234 0.4375,-0.28125l32.46875,-32.4375c0.39844,-0.38672 0.40234,-1.02344 0.01563,-1.42187c-0.38672,-0.39844 -1.02344,-0.40234 -1.42187,-0.01562l-32.28125,32.28125l-4.0625,-4.0625l32.28125,-32.28125c0.30078,-0.28906 0.39063,-0.73828 0.22266,-1.12109c-0.16797,-0.38281 -0.55469,-0.62109 -0.97266,-0.59766c-0.03125,0 -0.0625,0 -0.09375,0z"></path>
                              </g>
                            </g>
                          </svg>
                        </button>
                        {isDeletingId !== x.id && (
                          <button
                            onClick={() => setShowModal(x.id)}
                            className="approved"
                            style={{ background: "#ff435a52" }}
                            title="Edit"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0,0,256,256"
                              width="15px"
                              height="15px"
                              fill-rule="nonzero"
                            >
                              <g
                                fill="#fa525290"
                                fill-rule="nonzero"
                                stroke="none"
                                stroke-width="1"
                                stroke-linecap="butt"
                                stroke-linejoin="miter"
                                stroke-miterlimit="10"
                                stroke-dasharray=""
                                stroke-dashoffset="0"
                                font-family="none"
                                font-weight="none"
                                font-size="none"
                                text-anchor="none"
                              >
                                <g transform="scale(10.66667,10.66667)">
                                  <path d="M10.80664,2c-0.517,0 -1.01095,0.20431 -1.37695,0.57031l-0.42969,0.42969h-5c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h16c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-5l-0.42969,-0.42969c-0.365,-0.366 -0.85995,-0.57031 -1.37695,-0.57031zM4.36523,7l1.52734,13.26367c0.132,0.99 0.98442,1.73633 1.98242,1.73633h8.24805c0.998,0 1.85138,-0.74514 1.98438,-1.74414l1.52734,-13.25586z"></path>
                                </g>
                              </g>
                            </svg>
                          </button>
                        )}
                        {showModal === x.id && (
                          <div
                            className="modal-overlay-Deletevar"
                            onClick={handleOverlayClick}
                          >
                            <div className="modal-content">
                              <h2>Confirm Delete</h2>
                              <p>
                                Are you sure you want to delete this variation?
                              </p>
                              <div className="modal-buttons">
                                <button
                                  className="cancel-button"
                                  onClick={() => setShowModal(null)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="delete-button"
                                  onClick={() => handleConfirm(x)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {isDeletingId === x.id && isDeleting && (
                          <button
                            onClick={handleUndo}
                            className="approved"
                            style={{ background: "#ff435a52" }}
                            title="Edit"
                          >
                            <span>
                              Click to undo delete in {countdown}s{" "}
                              <CircularProgress color="danger" size="10px" />
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
            <div className="dividertable"></div>
            {/* <div
              className="DataTable"
              style={{ gap: "27px", alignItems: "center" }}
            >
              <div className="Item Total"> </div>
          
            </div> */}
            <div
              className="DataTable VariationKPIJobDatarow"
              style={{ gap: "27px", alignItems: "center" }}
            >
              <div className="Item Total"> </div>
              <div className="Item">
                {sumOfFields && sumOfFields.revenue
                  ? numeral(sumOfFields.revenue).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.labour_est_revenue
                  ? numeral(sumOfFields.labour_est_revenue).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.product_est_revenue
                  ? numeral(sumOfFields.product_est_revenue).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.plant_rental_and_equip_est_revenue
                  ? numeral(
                      sumOfFields.plant_rental_and_equip_est_revenue
                    ).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.sub_contract_est_revenue
                  ? numeral(sumOfFields.sub_contract_est_revenue).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.other_charges_charge_out
                  ? numeral(sumOfFields.other_charges_charge_out).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.margin
                  ? numeral(sumOfFields.margin).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.revenue
                  ? numeral(sumOfFields.revenue).format("$0,0")
                  : "-"}
              </div>
              <div className="Item">
                {sumOfFields && sumOfFields.est_labour_hours_total
                  ? `${sumOfFields.est_labour_hours_total.toFixed(0)}H`
                  : "-"}
              </div>

              <div className="Item"></div>
              <div className="Item"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="kpisummerysubtext">
        Quotech also checks against simpro and if the total revised contract
        value /= total value in simpro then it raises a flag and estimated
        working hours
      </div>
    </>
  );
};
export default Variation;
