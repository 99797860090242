import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * DateRangeFilter Component
 * A reusable date-range filter using the original Filters.jsx structure.
 * It is controlled by the parent via the `value` prop (an object { startDate, endDate }).
 * The selected date range is saved in sessionStorage using storageKey.
 *
 * Props:
 * - label: Filter label (e.g. "Date Range")
 * - storageKey: Key to use in sessionStorage (e.g. "dateRange_jobs")
 * - value: Object with { startDate, endDate }.
 * - onChange: Callback function({ startDate, endDate }) when either date changes.
 */
const DateRangeFilter = ({ label, storageKey, value, onChange }) => {
  const popupRef = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  // Update storage when value changes.
  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  // Close popup on outside click.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleStartDateChange = (e) => {
    onChange({ ...value, startDate: e.target.value });
  };

  const handleEndDateChange = (e) => {
    onChange({ ...value, endDate: e.target.value });
  };

  const displayText =
    value.startDate || value.endDate
      ? `From: ${value.startDate || "Any"} To: ${value.endDate || "Any"}`
      : "All";

  return (
    <div className="Filters" ref={popupRef}>
      <div className="Item" onClick={togglePopup}>
        <div className="items">
          <div className="Name">{label} :</div>
          <div className="FilterItems">{displayText}</div>
        </div>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.76117 0.5L5 3.51432L1.23883 0.5L0 1.49284L5 5.5L10 1.49284L8.76117 0.5Z"
            fill="#0035F0"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="FilterOptions">
          <div className="Options">
            <label>Start Date:</label>
            <input
              type="date"
              value={value.startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className="Options">
            <label>End Date:</label>
            <input
              type="date"
              value={value.endDate}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DateRangeFilter.propTypes = {
  label: PropTypes.string.isRequired,
  storageKey: PropTypes.string.isRequired,
  value: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateRangeFilter;
