import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * TagsFilter Component
 * A multi-select filter that mimics the original structure and classnames.
 * It is controlled by the parent via the `selectedTags` prop.
 * Selected tags are saved to sessionStorage using storageKey.
 *
 * Props:
 * - name: Label for the filter (e.g. "Tags")
 * - options: Array of objects each with { id, tag }
 * - storageKey: Key for saving selected tags (e.g. "tags_jobs")
 * - selectedTags: Array of selected tag strings.
 * - onChange: Callback function(newSelectedTags) when selection changes.
 * - handleAddTags (optional): Callback when a new tag is created.
 */
const TagsFilter = ({ name, options, storageKey, selectedTags, onChange, handleAddTags }) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const colors = ["#F0E9FF", "#60EF88", "#B0A781", "#F9E0E0"];

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(selectedTags));
  }, [selectedTags, storageKey]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectTag = (option) => {
    if (selectedTags.includes(option.tag)) {
      onChange(selectedTags.filter((tag) => tag !== option.tag));
    } else {
      onChange([...selectedTags, option.tag]);
    }
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value;
      if (newTag.trim() !== "") {
        if (handleAddTags) {
          handleAddTags(newTag.trim());
        }
        if (!selectedTags.includes(newTag.trim())) {
          onChange([...selectedTags, newTag.trim()]);
        }
        setInputValue("");
      }
      event.preventDefault();
    }
  };

  const SelectedTagsData = selectedTags.length > 0 ? selectedTags.join(", ") : "";
  const displayText =
    SelectedTagsData.length > 10 ? SelectedTagsData.slice(0, 10) + "..." : SelectedTagsData;

  return (
    <div className="Filters" ref={popupRef}>
      <div className="Item" onClick={togglePopup}>
        <div className="items">
          <div className="Name">{name} :</div>
          <div className="FilterItems">{selectedTags.length > 0 ? displayText : "All"}</div>
        </div>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.76117 0.5L5 3.51432L1.23883 0.5L0 1.49284L5 5.5L10 1.49284L8.76117 0.5Z"
            fill="#0035F0"
          />
        </svg>
      </div>
      {isOpen && (
        <div>
          <div className="FilterOptions">
            <div className="TagOptions">
              {options.length > 0 ? (
                options.map((option, i) => (
                  <div
                    className={`Options ${selectedTags.includes(option.tag) ? "Active" : ""}`}
                    key={option.id}
                    onClick={() => handleSelectTag(option)}
                    style={{ backgroundColor: colors[i % colors.length] }}
                  >
                    <div className={`Filter-checkbox ${selectedTags.includes(option.tag) ? "Active" : ""}`}></div>
                    <div className="Option">{option.tag}</div>
                  </div>
                ))
              ) : (
                <div className="Options">No tags</div>
              )}
            </div>
            {options.length > 0 && (
              <div onClick={() => setIsOpen(false)} className="GoButton">
                Filter &rarr;
              </div>
            )}
            <div className="Options">
              <input
                type="text"
                placeholder="Create new..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleInputKeyPress}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TagsFilter.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tag: PropTypes.string,
    })
  ).isRequired,
  storageKey: PropTypes.string.isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  handleAddTags: PropTypes.func,
};

export default TagsFilter;
