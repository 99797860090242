import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddJob,
  ClientList,
  EmployeesListManager,
  EmployeesListSales,
  InactiveJobPopup,
  InactivePopup,
  SiteList,
  Tags,
  UpdateJob,
} from "../../reducers/AllApis";
import InputFieldSearch from "./SearchInput";

const AddJobs = forwardRef(({ initialData }, ref) => {
  const dispatch = useDispatch();
  const {
    ClientListData,
    SiteListData,
    EmployeesListSalesData,
    EmployeesListManagerData,
    TagsData,
  } = useSelector((state) => state.allapi);
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };

  const [formData, setFormData] = useState({
    job_id:
      initialData !== null && initialData.job_id ? initialData.job_id : "",
    status:
      initialData !== null && initialData.status ? initialData.status : "",
      sales: initialData !== null && initialData.sales ? initialData.sales : "",
    project_manager:
      initialData !== null && initialData.project_manager
        ? initialData.project_manager
        : "",
    type: initialData !== null && initialData.type ? initialData.type : "",
    start_date:
      initialData !== null && initialData.start_date
        ? initialData.start_date
        : "",
    end_date:
      initialData !== null && initialData.end_date ? initialData.end_date : "",
    // tag: initialData !== null && initialData.status ? initialData.status : "",
    client:
      initialData !== null && initialData.client ? initialData.client : "",
    site: initialData !== null && initialData.site ? initialData.site : "",
  });

  const getPopulatedData = (formData) => {
    return Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClientSelect = (e) => {
    if (e.target.value.trim() !== "") {
      const debounce = setTimeout(() => {
        dispatch(ClientList(e.target.value));
      }, 2000);
      return () => clearTimeout(debounce);
    }
  };

  const handleSiteSelect = (e) => {
    if (e.target.value.trim() !== "") {
      const debounce = setTimeout(() => {
        dispatch(SiteList(e.target.value));
      }, 2000);
      return () => clearTimeout(debounce);
    }
  };

  const handleManagerSelect = (e) => {
    if (e.target.value.trim() !== "") {
      const debounce = setTimeout(() => {
        dispatch(EmployeesListManager(e.target.value));
      }, 2000);
      return () => clearTimeout(debounce);
    }
  };

  const handleSalesSelect = (e) => {
    if (e.target.value.trim() !== "") {
      const debounce = setTimeout(() => {
        dispatch(EmployeesListSales(e.target.value));
      }, 2000);
      return () => clearTimeout(debounce);
    }
  };

  const handleTagsSelect = (e) => {
    if (e.target.value.trim() !== "") {
      const debounce = setTimeout(() => {
        dispatch(Tags(e.target.value));
      }, 2000);
      return () => clearTimeout(debounce);
    }
  };

  const handleSubmit = () => {
    if (initialData !== null) {
      const changedFields = Object.keys(formData).reduce((acc, key) => {
    
        const initial = initialData[key] ?? ""; 
        const current = formData[key];
  
   
        if (current !== initial) {
          acc[key] = current;
        }
  
        return acc;
      }, {});
  
      changedFields.job_id = initialData.job_id; 
  
      const obj = {
        id: initialData.job_id,
        formdata: changedFields,
      };

      dispatch(UpdateJob(obj));
    }
  };
  

//   const handleSubmit = () => {
//     if (initialData !== null) {
//       const changedFields = Object.keys(formData).reduce((acc, key) => {

        
//         if (formData[key] !== initialData[key]) {
//           acc[key] = formData[key];
//         }
//         return acc;
//       }, {});
//       changedFields.job_id = initialData.job_id;

//       const obj = {
//         id: initialData.job_id,
//         formdata: changedFields,
//       };

//       // dispatch(UpdateJob(obj));
//     }
//   };

  return (
    <div className="AddJobsComp JobComp" ref={ref}>
      <div className="Close">
        <svg
          onClick={() => dispatch(InactiveJobPopup())}
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.9 14L0.5 12.6L6.1 7L0.5 1.4L1.9 0L7.5 5.6L13.1 0L14.5 1.4L8.9 7L14.5 12.6L13.1 14L7.5 8.4L1.9 14Z"
            fill="currentcolor"
          />
        </svg>
      </div>
      <div>
        <InputFieldSearch
          label="Sales"
          value={formData.sales}
          onChangeHandler={handleSalesSelect}
          searchData={EmployeesListSalesData}
          placeholder="Search for Sales Person"
          setFormData={setFormData}
          formKey="sales"
        />
        <InputFieldSearch
          label="Project Manager"
          value={formData.project_manager}
          onChangeHandler={handleManagerSelect}
          searchData={EmployeesListManagerData}
          placeholder="Search for Project Manager"
          setFormData={setFormData}
          formKey="project_manager"
        />
        {/* <InputFieldSearch
            label="Tags"
            value={formData.tag}
            onChangeHandler={handleTagsSelect}
            searchData={TagsData}
            placeholder="Search for site"
            setFormData={setFormData}
            formKey="tag"
          /> */}
        <div className="InputDiv">
          <div className="Head">Job Number</div>
          <div className="Input">
            <input
              name="job_id"
              value={formData.job_id}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">status</div>
          <div className="Input">
            <select
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value=""></option>
              <option value="tbc">TBC</option>
              <option value="wip*">WIP*</option>
              <option value="wip">WIP</option>
              <option value="done">DONE</option>
            </select>
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">type</div>
          <div className="Input">
            <select
              name="type"
              value={formData.type}
              onChange={handleInputChange}
            >
              <option value=""></option>
              <option value="commercial">Commercial</option>
              <option value="civil">Civil</option>
              <option value="remediation">Remediation</option>
              <option value="basement">Basement</option>
            </select>
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">Start date</div>
          <div className="Input">
            <input
            className="date-class"
              type="date"
              name="start_date"
              value={
                formData.start_date !== "" && initialData.start_date
                  ? formatDate(formData.start_date)
                  : formData.start_date
              }
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">End date</div>
          <div className="Input">
            <input
              type="date"
              name="end_date"
              value={
                formData.end_date !== "" && initialData.end_date
                  ? formatDate(formData.end_date)
                  : formData.end_date
              }
              onChange={handleInputChange}
            />
          </div>
        </div>
        <InputFieldSearch
          label="Select Client"
          value={formData.client}
          onChangeHandler={handleClientSelect}
          searchData={ClientListData.map((x) => ({
            id: x.id,
            name: x.client_name,
          }))}
          placeholder="Search for Client"
          setFormData={setFormData}
          formKey="client"
        />

        <InputFieldSearch
          label="Add Site Address"
          value={formData.site}
          onChangeHandler={handleSiteSelect}
          searchData={SiteListData.map((x) => ({
            id: x.id,
            name: x.name,
          }))}
          placeholder="Search for Site"
          setFormData={setFormData}
          formKey="site"
        />

        <div className="CreateJob CreateJobButton">
          <button onClick={handleSubmit}>
            {initialData ? "Update Job" : "Create Job"}
          </button>
        </div>
      </div>
    </div>
  );
});

export default AddJobs;
