import React, { useState } from "react";
import LabourAnalysis from "./LabourAnalysis";
import { LineChart } from "@mui/x-charts/LineChart";
import CircularProgress from "@mui/joy/CircularProgress";
import progressicon from "../../../assets/images/progressicon.svg";
import labourtarget from "../../../assets/images/labourtarget.svg";
import actualMargin from "../../../assets/images/red-Vector.svg";
import gainLoss from "../../../assets/images/blue-Vector.svg";
import numeral from "numeral";
import ProjectCost from "../ProjectCost";
import {
  LabourCircular,
  TotalLabCOCircular,
  TotalLabCOProgress,
} from "../../../utils/utils";

const Labour = ({
  GetLabourStatData,
  JobData,
  PerLabRevInvoiceTotal,
  TotalLabourChargeoutInvoiceTotal,
  QuotesListData,
  LabourInsightsHeader,
  TotalActualLabourRev,
  ActualLabourRev,
  TotalActualLabourCost,
  ActualLabourCostArr,
  TotalGrossPAL,
  GrossPAL,
  TotalPerMargin,
  PercentMargin,
  Time_Sheet,
  TotalForecastedLabourRevenue,
  ForecastedLabourRevenue,
  TotalLabourRevenueVariance,
  LabourRevenueVariance,
  TotalLabourRevenueVariancePer,
  LabourRevenueVariancePer,
  TotalActualLabourHours,
  ActualLabourHours,
  TotalLabourHoursTarget,
  LabourHoursTarget,
  TotalLabourHourLG,
  LabourHourLG,
  ActualLabourRevArr,
  Quote_Sheets,
  TotalLabourChargeout,
  PerLabRev,
  BudgetedLabourCostArr,
  TotalBudgetedLabourCost,
  TotalEarnedValueArr,
  EarnedValueArr,
  InvoicingTotalArr,
  TotalInvoicingTotalArr,
}) => {
  return (
    <div>
      <div className="LabourInvoice">
        <div className="flex projectcostsec">
          <div className="labourcard ProjectChartDiv ProjectChartdesk">
            <ProjectCost
              ActualLabourRevArr={ActualLabourRevArr}
              ActualLabourCostArr={ActualLabourCostArr}
              GrossPAL={GrossPAL}
              Time_Sheet={Time_Sheet}
              EarnedValueArr={EarnedValueArr}
            />
          </div>
          <div>
            <div className="labourchargeoutcard">
              <div
                className="labourcard flex chargeoutspace StatCard"
                style={{
                  marginBottom: "20px",
                  gap: "10px",
                  alignItems: "center",
                  width: "270px",
                }}
              >
                <div className="LabourStat">
                  {/* <div>{TotalLabCOCircular(PerLabRev, progressicon)}</div> */}
                  {/* <div>{TotalLabCOCircular(PerLabRev)}</div> */}
                  <div>{TotalLabCOCircular(PerLabRevInvoiceTotal)}</div>
                  <div
                    className="flex"
                    style={{
                      justifyContent: "center",
                      marginTop: "-42px",
                      marginBottom: "20px",
                      marginLeft: "-6px",
                    }}
                  >
                    <div className="chargeoutcircle">
                      <img src={progressicon} />
                    </div>
                  </div>
                  <div
                    className="subtitle flex"
                    style={{ justifyContent: "center" }}
                  >
                    {PerLabRevInvoiceTotal
                      ? `${PerLabRevInvoiceTotal.toFixed(1)}%`
                      : "0"}
                    {/* {PerLabRev ? `${PerLabRev.toFixed(1)}%` : "0"} */}
                  </div>
                </div>
                <div>
                  <div
                    className="flex LabourStat"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="subtitle">
                      {/* after replacing with total invoice */}
                      {TotalLabourChargeoutInvoiceTotal
                        ? numeral(TotalLabourChargeoutInvoiceTotal).format(
                            "$0,0"
                          )
                        : "0"}
                      {/* {TotalLabourChargeout
                        ? numeral(TotalLabourChargeout).format("$0,0")
                        : "0"} */}
                    </div>
                    <div className="subtitle">
                      {/* {Quote_Sheets?.total_project
                        ? numeral(Quote_Sheets?.total_project).format("$0,0")
                        : "0"} */}
                      {/* {QuotesListData && QuotesListData.revenue ? numeral(QuotesListData.revenue).format("$0,0") : ""} */}
                      
                      


                      {/* new labour stat */}

                      {GetLabourStatData && GetLabourStatData.total_revenue
                        ? numeral(GetLabourStatData.total_revenue).format("$0,0")
                        : ""}

                      {/* {JobData && JobData.total_revenue
                        ? numeral(JobData.total_revenue).format("$0,0")
                        : ""} */}
                    </div>
                  </div>
                  <div className="labourStatBar">
                    {TotalLabCOProgress(PerLabRevInvoiceTotal)}
                  </div>

                  <div className="labourbar">Total Labour Chargeout $</div>
                </div>
              </div>
              <div className="labourcard StatCard" style={{ width: "270px" }}>
                <div className="cardHead">Labour Statistics</div>
                <div className="flex" style={{ gap: "30px" }}>
                  <div style={{ gap: "33px" }}>
                    <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">Budgeted Labour Hours</div>
                      <div className="cardcontent">
                        {GetLabourStatData &&
                        GetLabourStatData.budgeted_labour_hours
                          ? numeral(
                              GetLabourStatData.budgeted_labour_hours
                            ).format("0,0")
                          : "0"}
                        H
                        {/* {Quote_Sheets?.est_labour_hours_total
                          ? numeral(
                              Quote_Sheets?.est_labour_hours_total
                            ).format("0,0")
                          : "0"}
                        H */}
                      </div>
                    </div>
                    {/* <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">Hours Targeted</div>
                      <div className="cardcontent">
                        {Quote_Sheets?.est_labour_hours_total
                          ? numeral(
                              Quote_Sheets?.est_labour_hours_total
                            ).format("0,0")
                          : "0"}
                        H
                      </div>
                    </div> */}
                  </div>

                  <div style={{ gap: "30px" }}>
                    <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">Actual Hours</div>
                      <div className="cardcontent">
                     
                        {TotalActualLabourHours
                          ? TotalActualLabourHours.toFixed(0)
                          : "0"}
                        H
                      </div>
                    </div>
                    {/* <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">Hours consumed</div>
                      <div className="cardcontent">
                        {TotalActualLabourHours
                          ? TotalActualLabourHours.toFixed(0)
                          : "0"}
                        H
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="divider"></div>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div style={{ gap: "30px" }}>
                    <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">Labour Chargeout ($ / H)</div>
                      <div className="cardcontent">
                      {GetLabourStatData && GetLabourStatData.labour_chargeout_per_hour
                          ? numeral(
                            GetLabourStatData.labour_chargeout_per_hour
                            ).format("$0,0")
                          : "0"}
                        {/* {Quote_Sheets?.labour_revenue_per_hour
                          ? numeral(
                              Quote_Sheets?.labour_revenue_per_hour
                            ).format("$0,0")
                          : "0"} */}
                      </div>
                    </div>
                    <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">% Target Margin</div>
                      <div className="cardcontent">
                      {GetLabourStatData && GetLabourStatData.percent_target_margin ? GetLabourStatData.percent_target_margin.toFixed(1) : "0.00"}%

                        {/* {Quote_Sheets?.labour_gross_profit_percent
                          ? Quote_Sheets?.labour_gross_profit_percent.toFixed(1)
                          : "0"}
                        % */}
                      </div>
                    </div>
                    <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">Total Labour Chargeout</div>
                      <div className="cardcontent">
                   
                        {TotalLabourChargeout
                          ? numeral(TotalLabourChargeout).format("$0,0")
                          : "0"}
                      </div>
                    </div>
                  </div>
                  <div style={{ gap: "30px" }}>
                    <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">% Actual Margin</div>
                      <div className="cardcontent">
                        
                        {TotalPerMargin ? TotalPerMargin.toFixed(1) : "0.00"}%
                      </div>
                    </div>
                    <div style={{ gap: "10px", marginBottom: "10px" }}>
                      <div className="cardTitle">Labour CTC / H</div>
                      <div className="cardcontent">
                           {GetLabourStatData && GetLabourStatData.labour_ctc_per_hour
                          ? numeral(GetLabourStatData.labour_ctc_per_hour).format(
                              "$0,0"
                            )
                          : "0"}
                        {/* {Quote_Sheets?.labour_ctc_per_hour
                          ? numeral(Quote_Sheets?.labour_ctc_per_hour).format(
                              "$0,0"
                            )
                          : "0"} */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div className="cardsubtitle labourspace">Labour</div>
                  <div
                    className="flex LabourStat"
                    style={{ gap: "20px", justifyContent: "space-between" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div className="lobourprogress">
                        {LabourCircular(
                          Quote_Sheets?.labour_gross_profit_percent || 0,
                          labourtarget,
                          "rgba(255, 184, 0, 1)",
                          "TargetMargin"
                        )}
                      </div>
                      <div className="subtitle">
                        {Quote_Sheets?.labour_gross_profit_percent
                          ? Quote_Sheets?.labour_gross_profit_percent.toFixed(0)
                          : "0"}
                        %
                      </div>
                      <div className="content">Target Margin</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div className="">
                        {LabourCircular(
                          TotalPerMargin || 0,
                          actualMargin,
                          "rgba(255, 184, 0, 1)",
                          "ActualMargin"
                        )}
                      </div>
                      <div className="subtitle">
                        {TotalPerMargin ? TotalPerMargin.toFixed(0) : "0"}%
                      </div>
                      <div className="content">Actual Margin</div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div className="">
                        {LabourCircular(
                          50,
                          gainLoss,
                          "rgba(255, 184, 0, 1)",
                          "GainLoss"
                        )}
                      </div>
                      <div className="subtitle">
                        {TotalLabourRevenueVariancePer
                          ? TotalLabourRevenueVariancePer.toFixed(0)
                          : "0"}
                        %
                      </div>
                      <div className="content">% Gain / Loss</div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="labourcard ProjectChartDiv  ProjectChartres">
            <ProjectCost
                ActualLabourRevArr={ActualLabourRevArr}
                ActualLabourCostArr={ActualLabourCostArr}
                GrossPAL={GrossPAL}
                Time_Sheet={Time_Sheet}
                EarnedValueArr={EarnedValueArr}
              />
          </div> */}
        </div>
      </div>

      <LabourAnalysis
        ActualLabourRevArr={ActualLabourRevArr}
        LabourInsightsHeader={LabourInsightsHeader}
        TotalActualLabourRev={TotalActualLabourRev}
        ActualLabourRev={ActualLabourRev}
        TotalActualLabourCost={TotalActualLabourCost}
        ActualLabourCostArr={ActualLabourCostArr}
        TotalGrossPAL={TotalGrossPAL}
        GrossPAL={GrossPAL}
        TotalPerMargin={TotalPerMargin}
        PercentMargin={PercentMargin}
        Time_Sheet={Time_Sheet}
        TotalForecastedLabourRevenue={TotalForecastedLabourRevenue}
        ForecastedLabourRevenue={ForecastedLabourRevenue}
        TotalLabourRevenueVariance={TotalLabourRevenueVariance}
        LabourRevenueVariance={LabourRevenueVariance}
        TotalLabourRevenueVariancePer={TotalLabourRevenueVariancePer}
        LabourRevenueVariancePer={LabourRevenueVariancePer}
        TotalActualLabourHours={TotalActualLabourHours}
        ActualLabourHours={ActualLabourHours}
        TotalLabourHoursTarget={TotalLabourHoursTarget}
        LabourHoursTarget={LabourHoursTarget}
        TotalLabourHourLG={TotalLabourHourLG}
        LabourHourLG={LabourHourLG}
        BudgetedLabourCostArr={BudgetedLabourCostArr}
        TotalBudgetedLabourCost={TotalBudgetedLabourCost}
        TotalEarnedValueArr={TotalEarnedValueArr}
        EarnedValueArr={EarnedValueArr}
        InvoicingTotalArr={InvoicingTotalArr}
        TotalInvoicingTotalArr={TotalInvoicingTotalArr}
      />
    </div>
  );
};

export default Labour;
