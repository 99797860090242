import React, { useState, useEffect } from "react";

// (Other imports such as job list component can be added here)
import StatusFilter from './../components/Filters/StatusFilter';
import TagsFilter from './../components/Filters/TagsFilter';
import DateRangeFilter from './../components/Filters/DateRangeFilter';

const JobNew = () => {

  
    // Define storage keys with suffix.
    const statusKey = "status_jobs";
    const tagsKey = "tags_jobs";
    const dateRangeKey = "dateRange_jobs";
  
    // Initialize filters from sessionStorage.
    const getInitialFilters = () => {
      const status = sessionStorage.getItem(statusKey) || "";
      let tags = [];
      try {
        tags = JSON.parse(sessionStorage.getItem(tagsKey)) || [];
      } catch {
        tags = [];
      }
      let dateRange = { startDate: "", endDate: "" };
      try {
        dateRange = JSON.parse(sessionStorage.getItem(dateRangeKey)) || dateRange;
      } catch {
        dateRange = { startDate: "", endDate: "" };
      }
      return { status, tags, dateRange };
    };
  
    const [filters, setFilters] = useState(getInitialFilters());
  
    // Handlers for controlled filters.
    const handleStatusChange = (newStatus) =>
      setFilters((prev) => ({ ...prev, status: newStatus }));
    const handleTagsChange = (newTags) =>
      setFilters((prev) => ({ ...prev, tags: newTags }));
    const handleDateRangeChange = (newRange) =>
      setFilters((prev) => ({ ...prev, dateRange: newRange }));
  
    // Build list of active filters for display.
    const activeFilters = [];
    if (filters.status) {
      activeFilters.push({ key: "status", label: "Status", value: filters.status });
    }
    if (filters.tags && filters.tags.length > 0) {
      activeFilters.push({
        key: "tags",
        label: "Tags",
        value: filters.tags.join(", "),
      });
    }
    if (filters.dateRange.startDate || filters.dateRange.endDate) {
      activeFilters.push({
        key: "dateRange",
        label: "Date Range",
        value: `From: ${filters.dateRange.startDate || "Any"} To: ${
          filters.dateRange.endDate || "Any"
        }`,
      });
    }
  
    // Remove an active filter.
    const removeFilter = (filterKey) => {
      if (filterKey === "status") {
        setFilters((prev) => ({ ...prev, status: "" }));
      } else if (filterKey === "tags") {
        setFilters((prev) => ({ ...prev, tags: [] }));
      } else if (filterKey === "dateRange") {
        setFilters((prev) => ({ ...prev, dateRange: { startDate: "", endDate: "" } }));
      }
    };
  
    return (
      <div className="job-new-page">
        <h1>Jobs</h1>
        <div className="filters-container">
          {/* Render the controlled StatusFilter */}
          <StatusFilter
            name="Status"
            options={["TBC", "WIP*", "WIP", "DONE"]}
            storageKey={statusKey}
            value={filters.status}
            onChange={handleStatusChange}
          />
  
          {/* Render the controlled TagsFilter */}
          <TagsFilter
            name="Tags"
            options={[
              { id: 1, tag: "Urgent" },
              { id: 2, tag: "High Priority" },
              { id: 3, tag: "Follow-up" },
            ]}
            storageKey={tagsKey}
            selectedTags={filters.tags}
            onChange={handleTagsChange}
          />
  
          {/* Render the controlled DateRangeFilter */}
          <DateRangeFilter
            label="Date Range"
            storageKey={dateRangeKey}
            value={filters.dateRange}
            onChange={handleDateRangeChange}
          />
        </div>
  
        {/* Active filters section */}
        {activeFilters.length > 0 && (
          <div className="active-filters-section">
            <h3>Selected Filters:</h3>
            <div className="active-filters">
              {activeFilters.map((filter) => (
                <div
                  key={filter.key}
                  className="active-filter-tag"
                  onClick={() => removeFilter(filter.key)}
                  title="Click to remove this filter"
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    margin: "5px",
                    padding: "5px 10px",
                    background: "#eee",
                    borderRadius: "5px",
                  }}
                >
                  <strong>{filter.label}:</strong> {filter.value}{" "}
                  <span style={{ marginLeft: "8px", color: "red" }}>&times;</span>
                </div>
              ))}
            </div>
          </div>
        )}
  
        {/* Placeholder for job list filtered by the current filters */}
        <div className="job-list">
          <p>Filtered jobs will appear here based on:</p>
          <pre>{JSON.stringify(filters, null, 2)}</pre>
        </div>
      </div>
    );
  };
  
  export default JobNew;
